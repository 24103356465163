import { SVGProps } from 'react';
const RatingsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M7.98317 0.984392C6.55818 1.20627 5.43318 2.22502 5.05505 3.63439C4.9738 3.94376 4.94255 4.72189 4.9988 5.07189C5.1113 5.77813 5.48005 6.49376 5.99568 7.00938L6.20818 7.22188L6.0488 7.27813C5.54568 7.45313 4.80506 7.87813 4.27693 8.3C3.77693 8.7 3.03631 9.525 2.85506 9.8875C2.80193 9.99688 2.79256 10.2719 2.83631 10.3938C2.92381 10.6219 3.18318 10.7938 3.43631 10.7969C3.67381 10.7969 3.83006 10.6938 4.07068 10.375C4.36131 9.9875 4.67693 9.64688 4.97693 9.39375C6.3613 8.23438 8.1738 7.83751 9.92067 8.3125C11.0769 8.625 12.1113 9.34688 12.855 10.3531C13.1082 10.6938 13.2332 10.775 13.4988 10.775C13.7332 10.775 13.9113 10.6813 14.0332 10.4906C14.1207 10.3563 14.1269 10.325 14.1175 10.1188C14.1082 9.9125 14.0957 9.88125 13.955 9.675C13.2363 8.62188 12.0082 7.66876 10.8332 7.25626C10.7863 7.23751 10.8144 7.19688 10.9957 7.01563C11.4988 6.51251 11.8519 5.84688 11.9832 5.16251C12.0457 4.84064 12.0457 4.15939 11.9832 3.83751C11.7238 2.47501 10.7113 1.40939 9.34567 1.05627C9.00505 0.971892 8.3113 0.934393 7.98317 0.984392ZM8.91755 2.27814C9.76442 2.44064 10.4738 3.10939 10.6863 3.94064C10.7707 4.26564 10.7707 4.73439 10.6863 5.05939C10.4738 5.89063 9.76442 6.55938 8.91755 6.72188C7.7613 6.94063 6.60193 6.20313 6.3113 5.05939C6.22693 4.73439 6.22693 4.26564 6.3113 3.94064C6.60193 2.79689 7.7613 2.05939 8.91755 2.27814Z"
			fill={props.fill || props.stroke || 'url(#paint0_linear_8575_130855)'}
		/>
		<path
			d="M8.294 9.72673C8.244 9.7611 8.18463 9.80485 8.16588 9.82985C8.144 9.85485 8.04088 10.1392 7.93463 10.4674C7.82838 10.7923 7.72838 11.0861 7.71275 11.1142C7.65025 11.233 7.56275 11.2486 6.80338 11.2642L6.05963 11.2798L5.95963 11.3798C5.87526 11.4642 5.85963 11.508 5.84713 11.6673C5.83776 11.808 5.84713 11.8767 5.88463 11.933C5.90963 11.9767 6.15338 12.1673 6.42526 12.3642C6.69713 12.558 6.95025 12.7486 6.98775 12.7892C7.11588 12.9298 7.10963 12.9986 6.89088 13.6392C6.76275 14.008 6.68463 14.2923 6.68463 14.3736C6.68463 14.608 6.894 14.783 7.14088 14.7548C7.24713 14.7455 7.36588 14.6736 7.769 14.383C8.34713 13.9642 8.369 13.9517 8.49713 13.9517C8.62525 13.9517 8.64713 13.9642 9.22525 14.383C9.62837 14.6736 9.74712 14.7455 9.85337 14.7548C10.1002 14.783 10.3096 14.608 10.3096 14.3736C10.3096 14.2923 10.2284 14.0048 10.1065 13.6392C9.894 13.0142 9.8815 12.9423 9.99087 12.808C10.019 12.7705 10.2721 12.5736 10.5534 12.3736C10.8346 12.1705 11.0846 11.9736 11.1127 11.933C11.1471 11.8767 11.1565 11.8048 11.1471 11.6673C11.1346 11.508 11.119 11.4642 11.0346 11.3798L10.9346 11.2798L10.1909 11.2642C9.55962 11.2517 9.43462 11.2392 9.36587 11.1955C9.29712 11.1517 9.24713 11.033 9.07525 10.508C8.95963 10.158 8.84088 9.8486 8.81275 9.81423C8.68463 9.66735 8.4565 9.62985 8.294 9.72673Z"
			fill={props.fill || props.stroke || 'url(#paint1_linear_8575_130855)'}
		/>
		<path
			d="M2.95029 11.9142C2.90029 11.9486 2.84092 11.9924 2.82217 12.0174C2.80029 12.0424 2.69717 12.3267 2.59092 12.6549C2.48467 12.9798 2.38467 13.2736 2.36905 13.3017C2.30655 13.4205 2.21905 13.4361 1.45967 13.4517L0.715923 13.4673L0.615923 13.5673C0.531549 13.6517 0.515924 13.6955 0.503424 13.8548C0.494049 13.9923 0.503424 14.0642 0.537799 14.1173C0.562798 14.158 0.825298 14.3642 1.11905 14.5767C1.6253 14.9423 1.74717 15.0611 1.74717 15.2017C1.74717 15.2236 1.65655 15.508 1.54405 15.833C1.41905 16.1986 1.34092 16.4798 1.34092 16.5611C1.34092 16.7955 1.5503 16.9705 1.79717 16.9423C1.90342 16.933 2.02217 16.8611 2.4253 16.5705C3.00342 16.1517 3.02529 16.1392 3.15342 16.1392C3.28154 16.1392 3.30342 16.1517 3.88154 16.5705C4.28467 16.8611 4.40342 16.933 4.50967 16.9423C4.75654 16.9705 4.96592 16.7955 4.96592 16.5611C4.96592 16.4798 4.88467 16.1923 4.76279 15.8267C4.54404 15.183 4.53467 15.1142 4.66279 14.9767C4.70029 14.9361 4.95342 14.7455 5.22529 14.5517C5.49716 14.3548 5.74091 14.1642 5.76591 14.1205C5.80341 14.0642 5.81279 13.9955 5.80341 13.8548C5.79091 13.6955 5.77529 13.6517 5.69091 13.5673L5.59091 13.4673L4.84717 13.4517C4.21592 13.4392 4.09092 13.4267 4.02217 13.383C3.95342 13.3392 3.90342 13.2205 3.73154 12.6955C3.61592 12.3455 3.49717 12.0361 3.46904 12.0017C3.34092 11.8549 3.11279 11.8174 2.95029 11.9142Z"
			fill={props.fill || props.stroke || 'url(#paint2_linear_8575_130855)'}
		/>
		<path
			d="M13.6378 11.9142C13.5878 11.9486 13.5284 11.9924 13.5097 12.0174C13.4878 12.0424 13.3847 12.3267 13.2784 12.6549C13.1722 12.9798 13.0722 13.2736 13.0565 13.3017C12.994 13.4205 12.9065 13.4361 12.1472 13.4517L11.4034 13.4673L11.3034 13.5673C11.219 13.6517 11.2034 13.6955 11.1909 13.8548C11.1815 13.9923 11.1909 14.0642 11.2253 14.1173C11.2503 14.158 11.5128 14.3642 11.8065 14.5767C12.3128 14.9423 12.4347 15.0611 12.4347 15.2017C12.4347 15.2236 12.344 15.508 12.2315 15.833C12.1065 16.1986 12.0284 16.4798 12.0284 16.5611C12.0284 16.7955 12.2378 16.9705 12.4847 16.9423C12.5909 16.933 12.7097 16.8611 13.1128 16.5705C13.6909 16.1517 13.7128 16.1392 13.8409 16.1392C13.969 16.1392 13.9909 16.1517 14.569 16.5705C14.9722 16.8611 15.0909 16.933 15.1972 16.9423C15.444 16.9705 15.6534 16.7955 15.6534 16.5611C15.6534 16.4798 15.5722 16.1923 15.4503 15.8267C15.2315 15.183 15.2222 15.1142 15.3503 14.9767C15.3878 14.9361 15.6409 14.7455 15.9128 14.5517C16.1847 14.3548 16.4284 14.1642 16.4534 14.1205C16.4909 14.0642 16.5003 13.9955 16.4909 13.8548C16.4784 13.6955 16.4628 13.6517 16.3784 13.5673L16.2784 13.4673L15.5347 13.4517C14.9034 13.4392 14.7784 13.4267 14.7097 13.383C14.6409 13.3392 14.5909 13.2205 14.419 12.6955C14.3034 12.3455 14.1847 12.0361 14.1565 12.0017C14.0284 11.8549 13.8003 11.8174 13.6378 11.9142Z"
			fill={props.fill || props.stroke || 'url(#paint3_linear_8575_130855)'}
		/>
		<defs>
			<linearGradient
				id="paint0_linear_8575_130855"
				x1="7.23396"
				y1="1.44621"
				x2="15.7929"
				y2="6.57646"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_8575_130855"
				x1="7.91979"
				y1="9.9235"
				x2="12.1316"
				y2="12.2143"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_8575_130855"
				x1="2.57604"
				y1="12.111"
				x2="6.78789"
				y2="14.4018"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_8575_130855"
				x1="13.2635"
				y1="12.111"
				x2="17.4754"
				y2="14.4018"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default RatingsIcon;
