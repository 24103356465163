import CourseIcon from '@/app/vv-search/courses/_icons/CourseIcon';
import GradientMicIcon from '@/app/vv-search/podcasts/[id]/subscribers/icon/GradientMicIcon';
import SubscriberIcon from '@/app/vv-search/podcasts/[id]/subscribers/icon/SubscriberIcon';
import EpisodeIcon from '@/app/vv-search/podcasts/_icons/EpisodeIcon';
import Customers from '@/components/icons/collectives/Customers';
import Followers from '@/components/icons/collectives/Followers';
import Members from '@/components/icons/collectives/Members';
import Consultation from '@/components/icons/collectives/PersonalProfile/Consultation';
import Order from '@/components/icons/collectives/PersonalProfile/Order';
import Reactions from '@/components/icons/collectives/PersonalProfile/Reactions';
import Services from '@/components/icons/collectives/PersonalProfile/Services';
import ShareMini from '@/components/icons/collectives/PersonalProfile/ShareMini';
import Social_Group from '@/components/icons/collectives/PersonalProfile/Social_Groups';
import Social_Posts from '@/components/icons/collectives/PersonalProfile/social_posts';
import Sponsor from '@/components/icons/collectives/PersonalProfile/Sponsor';
import Sales from '@/components/icons/collectives/Sales';
import TaggedIcon from '@/components/icons/reelsIcons/TaggedIcon';
import GradientProductsIcon from '@/components/icons/store/GradientProductsIcon';
import StoreGradientIcon from '@/components/icons/store/StoreGradientIcon';
import CommentIcon from '@/components/icons/v-blog/comment';
import LikeIcon from '@/components/icons/v-blog/like';
import LikesIcon from '@/components/icons/v-blog/likes';
import RatingsIcon from '@/components/icons/v-blog/ratings';
import ShareIcon from '@/components/icons/v-blog/share';
import VisitsIcon from '@/components/icons/v-blog/visits';
import Books from '@/components/icons/v-books/Books';
import Events from '@/components/icons/v-event/EventColorIcon';
import Applicants from '@/components/icons/v-job/Applicants';
import CalenderMoney from '@/components/icons/v-job/CalendarMoney';
import HiringIcon from '@/components/icons/v-job/HiringIcon';
import JobsIcon from '@/components/icons/v-job/JobsIcon';
import EndorsementsGray from '@/components/icons/v-jobs/EndorsementsGray';
import SalaryGray from '@/components/icons/v-jobs/SalarayGray';
import Comment2 from '@/components/icons/v-nation/Comment2';
import SocialPagesGradient from '@/components/icons/v-nation/SocialPagesGradient';
import WebinarGradient from '@/components/icons/v-webinar/WebinarGradient';
import formatNumber from '@/utils/common';

export const createActivityTypes = (customclass?: string) => {
	return [
		{
			id: 1,
			name: 'activities.celebrating',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/celebrating.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 2,
			name: 'activities.watching',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/watching.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 3,
			name: 'activities.listening',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/listening.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 4,
			name: 'activities.reading',
			icon: (
				<img className={customclass} src="/images/share-modal/reading.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 5,
			name: 'activities.eating',
			icon: (
				<img className={customclass} src="/images/share-modal/eating.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 6,
			name: 'activities.drinking',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/drinking.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 7,
			name: 'activities.lookingFor',
			icon: (
				<img className={customclass} src="/images/share-modal/looking.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 8,
			name: 'activities.visiting',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/visiting.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 9,
			name: 'activities.traveling',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/traveling.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 10,
			name: 'activities.attending',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/attending.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 11,
			name: 'activities.playing',
			icon: (
				<img className={customclass} src="/images/share-modal/playing.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
	];
};

export const createFeelingTypes = (customclass?: string) => {
	return [
		{
			id: 1,
			name: 'Happy',
			icon: (
				<img className={customclass} src="/images/share-modal/happy.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 2,
			name: 'Blessed',
			icon: (
				<img className={customclass} src="/images/share-modal/blessed.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 3,
			name: 'Tired',
			icon: (
				<img className={customclass} src="/images/share-modal/tired.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 4,
			name: 'Loved',
			icon: (
				<img className={customclass} src="/images/share-modal/loved.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 5,
			name: 'Sad',
			icon: (
				<img className={customclass} src="/images/share-modal/sad.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 6,
			name: 'Alone',
			icon: (
				<img className={customclass} src="/images/share-modal/alone.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 7,
			name: 'Lovely',
			icon: (
				<img className={customclass} src="/images/share-modal/lovely.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 8,
			name: 'Thankful',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/thankful.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 9,
			name: 'Sick',
			icon: (
				<img className={customclass} src="/images/share-modal/sick.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 10,
			name: 'Excited',
			icon: (
				<img className={customclass} src="/images/share-modal/excited.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 11,
			name: 'In Love',
			icon: (
				<img className={customclass} src="/images/share-modal/lovely.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 12,
			name: 'Drained',
			icon: (
				<img className={customclass} src="/images/share-modal/drained.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 13,
			name: 'Crazy',
			icon: (
				<img className={customclass} src="/images/share-modal/crazy.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 14,
			name: 'Grateful',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/thankful.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 15,
			name: 'Confident',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/thankful.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 16,
			name: 'Blissful',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/blissful.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 17,
			name: 'Fantastic',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/fantastic.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 18,
			name: 'Awesome',
			icon: (
				<img className={customclass} src="/images/share-modal/awesome.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 19,
			name: 'Silly',
			icon: (
				<img className={customclass} src="/images/share-modal/silly.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 20,
			name: 'Cool',
			icon: (
				<img className={customclass} src="/images/share-modal/cool.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 21,
			name: 'Fresh',
			icon: (
				<img className={customclass} src="/images/share-modal/awesome.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 22,
			name: 'Wonderful',
			icon: (
				<img className={customclass} src="/images/share-modal/awesome.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 23,
			name: 'Relaxed',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/blissful.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 24,
			name: 'Determined',
			icon: (
				<img className={customclass} src="/images/share-modal/awesome.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 25,
			name: 'Amused',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/thankful.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
		{
			id: 26,
			name: 'Chill',
			icon: (
				<img className={customclass} src="/images/share-modal/chill.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 27,
			name: 'Annoyed',
			icon: (
				<img className={customclass} src="/images/share-modal/annoyed.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 28,
			name: 'Joyful',
			icon: (
				<img className={customclass} src="/images/share-modal/joyful.png" alt="image" style={{ objectFit: 'cover' }} />
			),
		},
		{
			id: 29,
			name: 'Heartbroken',
			icon: (
				<img
					className={customclass}
					src="/images/share-modal/heartbroken.png"
					alt="image"
					style={{ objectFit: 'cover' }}
				/>
			),
		},
	];
};

interface VJobsData {
	counts?: {
		jobs_count?: number;
		applicants_count?: number;
		hirings_count?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
	};
	salary?: number;
	payment_cycle_enum?: number;
	reviews_count?: number;
	applicants_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
}

interface VBookData {
	counts?: {
		book_count?: number;
		sales_sum?: number;
		orders_count?: number;
		customers_count?: number;
		likes_count?: number;
		endorsements_count?: number;
		profile_likes_count?: number;
	};
	sales?: number;
	order_count?: number;
	customers_count?: number;
	likes_count?: number;
	reviews_count?: number;
	shares_count?: number;
	rating?: number;
}

interface VWebinarData {
	user?: {
		count?: {
			webinars_count?: number;
			sales_sum?: number;
			orders_count?: number;
			customers_count?: number;
			likes_count?: number;
			endorsements_count?: number;
		};
	};
	sales?: number;
	order_count?: number;
	customers_count?: number;
	likes_count?: number;
	rating?: number;
	shares_count?: number;
	reviews_count?: number;
}

interface VBuzzData {
	user?: {
		count?: {
			sponsorships_count?: number;
			sales_sum?: number;
			orders_count?: number;
			customers_count?: number;
			likes_count?: number;
			shares_count?: number;
			endorsements_count?: number;
		};
	};
	sales_sum?: number;
	sponsorship_order_count?: number;
	customers_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VNationSocialPageData {
	user?: {
		count?: {
			social_pages_count?: number;
			followers_count?: number;
			social_groups_count?: number;
			members_count?: number;
			likes_count?: number;
			endorsements_count?: number;
		};
	};
	posts_count?: number;
	followers_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VNationSocialPagePostData {
	comments_count?: number;
	likes_count?: number;
	shares_count?: number;
	tagged_users_count?: number;
}

interface VNationSocialGroupPostData {
	comments_count?: number;
	likes_count?: number;
	shares_count?: number;
	tagged_users_count?: number;
}

interface VNationTimelinePostData {
	comments_count?: number;
	likes_count?: number;
	shares_count?: number;
	tagged_users_count?: number;
}

interface VNationSocialGroupData {
	user?: {
		count?: {
			social_pages_count?: number;
			followers_count?: number;
			social_groups_count?: number;
			members_count?: number;
			likes_count?: number;
			endorsements_count?: number;
		};
	};
	posts_count?: number;
	members_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VLanceData {
	counts?: {
		sales_sum?: number;
		customers_count?: number;
		services_count?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
		projects_count?: number;
		orders_count?: number;
	};
	applicants_count?: number;
	likes_count?: number;
	shares_count?: number;
}

interface VBlogData {
	counts?: {
		sales_sum?: number;
		posts_count?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
		orders_count?: number;
	};
	comments_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VEventsData {
	counts?: {
		events_count?: number;
		sales_sum?: number;
		orders_count?: number;
		customers_count?: number;
		likes_count?: number;
		shares_count?: number;
		rating_sum?: number;
		reviews_count?: number;
	};
	sales?: number;
	orders_count?: number;
	customers_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VCastData {
	counts?: {
		podcasts_count?: number;
		episodes_count?: number;
		sales_sum?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
		orders_count?: number;
		customers_count?: number;
	};
	episodes_count?: number;
	subscribers_count?: number;
	sales?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VCollegeData {
	counts?: {
		courses_count?: number;
		sales_sum?: number;
		orders_count?: number;
		customers_count?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
	};
	sales?: number;
	orders_count?: number;
	customers_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VExpertsData {
	counts?: {
		sales_sum?: number;
		customers_count?: number;
		consultations_count?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
		projects_count?: number;
		orders_count?: number;
	};
	sales?: number;
	orders_count?: number;
	customers_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VMarketData {
	counts?: {
		stores_count?: number;
		sales_sum?: number;
		orders_count?: number;
		customers_count?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
	};
	products_count?: number;
	sales?: number;
	order_count?: number;
	customers_count?: number;
	likes_count?: number;
	shares_count?: number;
	rating?: number;
	reviews_count?: number;
}

interface VGuideData {
	counts?: {
		sales_sum?: number;
		likes_count?: number;
		shares_count?: number;
		endorsements_count?: number;
	};
}

type ModuleData =
	| VJobsData
	| VBookData
	| VWebinarData
	| VBuzzData
	| VLanceData
	| VBlogData
	| VEventsData
	| VCastData
	| VCollegeData
	| VExpertsData
	| VMarketData
	| VGuideData;
export const getStatsForModule = (
	module:
		| 'V-Jobs'
		| 'V-Book'
		| 'V-Webinars'
		| 'V-Buzz'
		| 'V-Jobs'
		| 'V-Nation'
		| 'V-Lance'
		| 'V-Blog'
		| 'V-Events'
		| 'V-Cast'
		| 'V-College'
		| 'V-Experts'
		| 'V-Market'
		| 'V-Guide',
	type: 'profile' | 'asset' = 'profile',
	data: ModuleData = {},
	shareable_type?:
		| 'socialPage'
		| 'socialGroup'
		| 'channel'
		| 'video'
		| 'socialPagePost'
		| 'socialGroupPost'
		| 'timelinePost'
		| 'store',
	t?: (s: string) => string
) => {
	const getAssetTypeForVNation = (shareableType: string) => {
		switch (shareableType) {
			case 'socialPage':
				return [
					{
						name: 'Soical Posts',
						count: (data as VNationSocialPageData)?.posts_count ?? 0,
						icon: <Social_Posts />,
					},
					{
						name: 'Followers',
						count: (data as VNationSocialPageData)?.followers_count ?? 0,
						icon: <Followers />,
					},
					{
						name: 'Reactions',
						count: (data as VNationSocialPageData)?.likes_count ?? 0,
						icon: <LikesIcon />,
					},
					{
						name: 'Shares',
						count: (data as VNationSocialPageData)?.shares_count ?? 0,
						icon: <ShareIcon />,
					},
					{
						name: 'Rating and Reviews',
						count: `${(data as VNationSocialPageData)?.rating ?? 0} (${(data as VNationSocialPageData)?.reviews_count ?? 0})`,
						icon: <RatingsIcon />,
					},
				];
			case 'socialGroup':
				return [
					{
						name: 'Soical Posts',
						count: (data as VNationSocialPageData)?.posts_count ?? 0,
						icon: <Social_Posts />,
					},
					{
						name: 'Members',
						count: (data as VNationSocialGroupData)?.members_count ?? 0,
						icon: <Members />,
					},
					{
						name: 'Reactions',
						count: (data as VNationSocialPageData)?.likes_count ?? 0,
						icon: <LikesIcon />,
					},
					{
						name: 'Shares',
						count: (data as VNationSocialPageData)?.shares_count ?? 0,
						icon: <ShareIcon />,
					},
					{
						name: 'Rating and Reviews',
						count: `${(data as VNationSocialPageData)?.rating ?? 0} (${(data as VNationSocialPageData)?.reviews_count ?? 0})`,
						icon: <RatingsIcon />,
					},
				];
			case 'socialPagePost':
				return [
					{
						name: 'Comments',
						count: (data as VNationSocialPagePostData)?.comments_count ?? 0,
						icon: <Comment2 />,
					},
					{
						name: 'Reactions',
						count: (data as VNationSocialPagePostData)?.likes_count ?? 0,
						icon: <LikesIcon />,
					},
					{
						name: 'Shares',
						count: (data as VNationSocialPagePostData)?.shares_count ?? 0,
						icon: <ShareIcon />,
					},
					{
						name: 'Tagged Users',
						count: (data as VNationSocialPagePostData)?.comments_count ?? 0,
						icon: <TaggedIcon />,
					},
				];
			case 'socialGroupPost':
				return [
					{
						name: 'Comments',
						count: (data as VNationSocialGroupPostData)?.comments_count ?? 0,
						icon: <Comment2 />,
					},
					{
						name: 'Reactions',
						count: (data as VNationSocialGroupPostData)?.likes_count ?? 0,
						icon: <LikesIcon />,
					},
					{
						name: 'Shares',
						count: (data as VNationSocialGroupPostData)?.shares_count ?? 0,
						icon: <ShareIcon />,
					},
					{
						name: 'Tagged Users',
						count: (data as VNationSocialGroupPostData)?.comments_count ?? 0,
						icon: <TaggedIcon />,
					},
				];
			case 'timelinePost':
				return [
					{
						name: 'Comments',
						count: (data as VNationTimelinePostData)?.comments_count ?? 0,
						icon: <Comment2 />,
					},
					{
						name: 'Reactions',
						count: (data as VNationTimelinePostData)?.likes_count ?? 0,
						icon: <LikesIcon />,
					},
					{
						name: 'Shares',
						count: (data as VNationTimelinePostData)?.shares_count ?? 0,
						icon: <ShareIcon />,
					},
					{
						name: 'Tagged Users',
						count: (data as VNationTimelinePostData)?.comments_count ?? 0,
						icon: <TaggedIcon />,
					},
				];
			default:
				return [{ name: 'Shares', count: 22225, icon: <ShareIcon /> }];
		}
	};
	const moduleStats: Record<
		string,
		Record<
			string,
			Array<{
				name: string;
				count: number | string;
				icon: JSX.Element;
			}>
		>
	> = {
		'V-Jobs': {
			profile: [
				{
					name: 'Jobs',
					count: (data as VJobsData)?.counts?.jobs_count ?? 0,
					icon: <JobsIcon />,
				},
				{
					name: 'Applicants',
					count: (data as VJobsData)?.counts?.applicants_count ?? 0,
					icon: <Applicants />,
				},
				{
					name: 'Hirings',
					count: (data as VJobsData)?.counts?.hirings_count ?? 0,
					icon: <HiringIcon />,
				},
				{
					name: 'Likes',
					count: (data as VJobsData)?.counts?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VJobsData)?.counts?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VJobsData)?.counts?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Salary',
					count: formatNumber(parseInt(String((data as VJobsData)?.salary ?? 0))),
					icon: <SalaryGray />,
				},
				{
					name: 'Payment Cycle',
					count: (data as VJobsData)?.payment_cycle_enum ?? 0,
					icon: <CalenderMoney />,
				},
				{
					name: 'Applicants',
					count: (data as VJobsData)?.applicants_count ?? 0,
					icon: <Applicants />,
				},
				{
					name: 'Reactions',
					count: (data as VJobsData)?.likes_count ?? 0,
					icon: <LikesIcon />,
				},
				{
					name: 'Shares',
					count: (data as VJobsData)?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Ratings & Reviews',
					count: `${(data as VJobsData)?.rating ?? 0} (${(data as VJobsData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Book': {
			profile: [
				{
					name: 'Books',
					count: (data as VBookData)?.counts?.book_count ?? 0,
					icon: <Books />,
				},
				{
					name: 'Sales',
					count: formatNumber((data as VBookData)?.counts?.sales_sum ?? 0),

					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VBookData)?.counts?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VBookData)?.counts?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VBookData)?.counts?.profile_likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VBookData)?.counts?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Sales',
					count: Number((data as VBookData)?.sales ?? 0).toFixed(2),
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VBookData)?.order_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VBookData)?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VBookData)?.likes_count ?? 0,
					icon: <LikesIcon />,
				},
				{
					name: 'Shares',
					count: (data as VBookData)?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VBookData)?.rating ?? 0} (${(data as VBookData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Webinars': {
			profile: [
				{
					name: 'Webinars',
					count: (data as VWebinarData)?.user?.count?.webinars_count ?? 0,
					icon: <WebinarGradient />,
				},
				{
					name: 'Sales',
					count: formatNumber((data as VWebinarData)?.user?.count?.sales_sum ?? 0),
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VWebinarData)?.user?.count?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VWebinarData)?.user?.count?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VWebinarData)?.user?.count?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VWebinarData)?.user?.count?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Sales',
					count: (data as VWebinarData)?.sales ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VWebinarData)?.order_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VWebinarData)?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Reactions',
					count: (data as VWebinarData)?.likes_count ?? 0,
					icon: <LikesIcon />,
				},
				{
					name: 'Shares',
					count: (data as VWebinarData)?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VWebinarData)?.rating ?? 0} (${(data as VWebinarData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Buzz': {
			profile: [
				{
					name: 'Sponsorship',
					count: (data as VBuzzData)?.user?.count?.sponsorships_count ?? 0,
					icon: <Sponsor />,
				},
				{
					name: 'Sales',
					count: (data as VBuzzData)?.user?.count?.sales_sum ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VBuzzData)?.user?.count?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VBuzzData)?.user?.count?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VBuzzData)?.user?.count?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VBuzzData)?.user?.count?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VBuzzData)?.user?.count?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Sales',
					count: (data as VBuzzData)?.sales_sum ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VBuzzData)?.sponsorship_order_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VBuzzData)?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Reactions',
					count: (data as VBuzzData)?.likes_count ?? 0,
					icon: <LikesIcon />,
				},
				{
					name: 'Shares',
					count: (data as VBuzzData)?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VBuzzData)?.rating ?? 0} (${(data as VBuzzData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Nation': {
			profile: [
				{
					name: 'Social Pages',
					count: (data as VNationSocialPageData | VNationSocialGroupData)?.user?.count?.social_groups_count ?? 0,
					icon: <SocialPagesGradient stroke="url(#paint1_linear_9662_220387)" />,
				},
				{
					name: 'Followers',
					count: (data as VNationSocialPageData | VNationSocialGroupData)?.user?.count?.followers_count ?? 0,
					icon: <Followers />,
				},
				{
					name: 'Social Groups',
					count: (data as VNationSocialPageData | VNationSocialGroupData)?.user?.count?.social_groups_count ?? 0,
					icon: <Social_Group />,
				},
				{
					name: 'Members',
					count: (data as VNationSocialPageData | VNationSocialGroupData)?.user?.count?.members_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VNationSocialPageData | VNationSocialGroupData)?.user?.count?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VNationSocialPageData | VNationSocialGroupData)?.user?.count?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: getAssetTypeForVNation(shareable_type!),
		},
		'V-Lance': {
			profile: [
				{
					name: 'Services',
					count: (data as VLanceData)?.counts?.services_count ?? 0,
					icon: <Services width={16} height={16} />,
				},
				{
					name: 'Sales',
					count: (data as VLanceData)?.counts?.sales_sum ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VLanceData)?.counts?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VLanceData)?.counts?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VLanceData)?.counts?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VLanceData)?.counts?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VLanceData)?.counts?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Applicants',
					count: (data as VLanceData)?.applicants_count ?? 0,
					icon: <Applicants />,
				},
				{
					name: 'Reactions',
					count: (data as VLanceData)?.likes_count ?? 0,
					icon: <Reactions />,
				},
				{
					name: 'Shares',
					count: (data as VLanceData)?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
			],
		},
		'V-Blog': {
			profile: [
				{
					name: 'Posts',
					count: `${formatNumber((data as VBlogData)?.counts?.posts_count ?? 0)}`,
					icon: <VisitsIcon />,
				},
				{
					name: 'Likes',
					count: `${formatNumber((data as VBlogData)?.counts?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VBlogData)?.counts?.shares_count ?? 0)}`,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: `${formatNumber((data as VBlogData)?.counts?.endorsements_count ?? 0)}`,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Comments',
					count: `${formatNumber((data as VBlogData)?.comments_count ?? 0)}`,
					icon: <CommentIcon />,
				},
				{
					name: 'Reactions',
					count: `${formatNumber((data as VBlogData)?.likes_count ?? 0)}`,
					icon: <Reactions />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VBlogData)?.shares_count ?? 0)}`,
					icon: <ShareIcon />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VBlogData)?.rating ?? 0} (${(data as VBlogData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Events': {
			profile: [
				{
					name: t ? t('VEvent.common.navItem.Events') : 'Events',
					count: `${formatNumber((data as VEventsData)?.counts?.events_count ?? 0)}`,
					icon: <Events />,
				},
				{
					name: t ? t('VEvent.common.navItem.sales') : 'Sales',
					count: `$${formatNumber((data as VEventsData)?.counts?.sales_sum ?? 0)}`,
					icon: <Sales />,
				},
				{
					name: t ? t('VEvent.common.navItem.orders') : 'Orders',
					count: `${formatNumber((data as VEventsData)?.counts?.orders_count ?? 0)}`,
					icon: <Order />,
				},
				{
					name: t ? t('VEvent.common.navItem.customers') : 'Customers',
					count: `${formatNumber((data as VEventsData)?.counts?.customers_count ?? 0)}`,
					icon: <Customers />,
				},
				{
					name: t ? t('VEvent.common.navItem.reactions') : 'Reactions',
					count: `${formatNumber((data as VEventsData)?.counts?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: t ? t('VEvent.common.navItem.shares') : 'Shares',
					count: `${formatNumber((data as VEventsData)?.counts?.shares_count ?? 0)}`,
					icon: <ShareMini />,
				},
				{
					name: t ? t('VEvent.common.navItem.ratingReviews') : 'Rating & Reviews',
					count: `${(data as VEventsData)?.counts?.rating_sum ?? 0} (${(data as VEventsData)?.counts?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
			asset: [
				{
					name: t ? t('VEvent.common.navItem.sales') : 'Sales',
					count: `$${formatNumber((data as VEventsData)?.sales ?? 0)}`,
					icon: <Sales />,
				},
				{
					name: t ? t('VEvent.common.navItem.orders') : 'Orders',
					count: `${formatNumber((data as VEventsData)?.orders_count ?? 0)}`,
					icon: <Order />,
				},
				{
					name: t ? t('VEvent.common.navItem.customers') : 'Customers',
					count: `${formatNumber((data as VEventsData)?.customers_count ?? 0)}`,
					icon: <Customers />,
				},
				{
					name: t ? t('VEvent.common.navItem.reactions') : 'Reactions',
					count: `${formatNumber((data as VEventsData)?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: t ? t('VEvent.common.navItem.shares') : 'Shares',
					count: `${formatNumber((data as VEventsData)?.shares_count ?? 0)}`,
					icon: <ShareMini />,
				},
				{
					name: t ? t('VEvent.common.navItem.ratingReviews') : 'Rating & Reviews',
					count: `${(data as VEventsData)?.rating ?? 0} (${(data as VEventsData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Cast': {
			profile: [
				{
					name: 'Podcasts',
					count: `${formatNumber((data as VCastData)?.counts?.podcasts_count ?? 0)}`,
					icon: <GradientMicIcon />,
				},
				{
					name: 'Episodes',
					count: `${formatNumber((data as VCastData)?.counts?.episodes_count ?? 0)}`,
					icon: <EpisodeIcon />,
				},
				{
					name: '$Sales',
					count: `${formatNumber((data as VCastData)?.counts?.sales_sum ?? 0)}`,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: `${formatNumber((data as VCastData)?.counts?.orders_count ?? 0)}`,
					icon: <Order />,
				},
				{
					name: 'Subscribers',
					count: `${formatNumber((data as VCastData)?.counts?.customers_count ?? 0)}`,
					icon: <SubscriberIcon />,
				},
				{
					name: 'Likes',
					count: `${formatNumber((data as VCastData)?.counts?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VCastData)?.counts?.shares_count ?? 0)}`,
					icon: <ShareMini />,
				},
				{
					name: 'Endorsements',
					count: `${formatNumber((data as VCastData)?.counts?.endorsements_count ?? 0)}`,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Episodes',
					count: `${formatNumber((data as VCastData)?.episodes_count ?? 0)}`,
					icon: <EpisodeIcon />,
				},
				{
					name: 'Subscribers',
					count: `${formatNumber((data as VCastData)?.subscribers_count ?? 0)}`,
					icon: <SubscriberIcon />,
				},
				{
					name: 'Sales',
					count: `$${formatNumber((data as VCastData)?.sales ?? 0)}`,
					icon: <Sales />,
				},
				{
					name: 'Reactions',
					count: `${formatNumber((data as VCastData)?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VCastData)?.shares_count ?? 0)}`,
					icon: <ShareMini />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VCastData)?.rating ?? 0} (${(data as VCastData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Cast-Episode': {
			profile: [
				{
					name: 'Podcasts',
					count: `${formatNumber((data as VCastData)?.counts?.podcasts_count ?? 0)}`,
					icon: <GradientMicIcon />,
				},
				{
					name: 'Episodes',
					count: `${formatNumber((data as VCastData)?.counts?.episodes_count ?? 0)}`,
					icon: <EpisodeIcon />,
				},
				{
					name: '$Sales',
					count: `${formatNumber((data as VCastData)?.counts?.sales_sum ?? 0)}`,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: `${formatNumber((data as VCastData)?.counts?.orders_count ?? 0)}`,
					icon: <Order />,
				},
				{
					name: 'Subscribers',
					count: `${formatNumber((data as VCastData)?.counts?.customers_count ?? 0)}`,
					icon: <SubscriberIcon />,
				},
				{
					name: 'Likes',
					count: `${formatNumber((data as VCastData)?.counts?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VCastData)?.counts?.shares_count ?? 0)}`,
					icon: <ShareMini />,
				},
				{
					name: 'Endorsements',
					count: `${formatNumber((data as VCastData)?.counts?.endorsements_count ?? 0)}`,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Plays',
					count: `${formatNumber((data as any)?.views_count ?? 0)}`,
					icon: <EpisodeIcon />,
				},
				{
					name: 'Comments',
					count: `${formatNumber((data as any)?.comments_count ?? 0)}`,
					icon: <Comment2 />,
				},
				{
					name: 'Reactions',
					count: `${formatNumber((data as VCastData)?.likes_count ?? 0)}`,
					icon: <Reactions />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VCastData)?.shares_count ?? 0)}`,
					icon: <ShareMini />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VCastData)?.rating ?? 0} (${(data as VCastData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-College': {
			profile: [
				{
					name: 'Courses',
					count: (data as VCollegeData)?.counts?.courses_count ?? 0,
					icon: <CourseIcon />,
				},
				{
					name: 'Sales',
					count: (data as VCollegeData)?.counts?.sales_sum ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VCollegeData)?.counts?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Subscribers',
					count: (data as VCollegeData)?.counts?.customers_count ?? 0,
					icon: <SubscriberIcon />,
				},
				{
					name: 'Likes',
					count: (data as VCollegeData)?.counts?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VCollegeData)?.counts?.shares_count ?? 0,
					icon: <ShareMini />,
				},
				{
					name: 'Endorsements',
					count: (data as VCollegeData)?.counts?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Sales',
					count: (data as VCollegeData)?.sales ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VCollegeData)?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VCollegeData)?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Reactions',
					count: (data as VCollegeData)?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VCollegeData)?.shares_count ?? 0,
					icon: <ShareMini />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VCollegeData)?.rating ?? 0} (${(data as VCollegeData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Experts': {
			profile: [
				{
					name: 'Consultations',
					count: (data as VExpertsData)?.counts?.consultations_count ?? 0,
					icon: <Consultation />,
				},
				{
					name: 'Sales',
					count: formatNumber((data as VExpertsData)?.counts?.sales_sum ?? 0),
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VExpertsData)?.counts?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VExpertsData)?.counts?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VExpertsData)?.counts?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VExpertsData)?.counts?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VExpertsData)?.counts?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				{
					name: 'Sales',
					count: formatNumber((data as VExpertsData)?.sales ?? 0),
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VExpertsData)?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VExpertsData)?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Reactions',
					count: (data as VExpertsData)?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VExpertsData)?.shares_count ?? 0,
					icon: <ShareMini />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VExpertsData)?.rating ?? 0} (${(data as VExpertsData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Market': {
			profile: [
				{
					name: 'Stores',
					count: (data as VMarketData)?.counts?.stores_count ?? 0,
					icon: <StoreGradientIcon />,
				},
				{
					name: 'Sales',
					count: (data as VMarketData)?.counts?.sales_sum ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VMarketData)?.counts?.orders_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VMarketData)?.counts?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Likes',
					count: (data as VMarketData)?.counts?.likes_count ?? 0,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: (data as VMarketData)?.counts?.shares_count ?? 0,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: (data as VMarketData)?.counts?.endorsements_count ?? 0,
					icon: <EndorsementsGray />,
				},
			],
			asset: [
				...(shareable_type === 'store'
					? [
							{
								name: 'Products',
								count: (data as VMarketData)?.products_count ?? 0,
								icon: <GradientProductsIcon />,
							},
						]
					: []),
				{
					name: 'Sales',
					count: (data as VMarketData)?.sales ?? 0,
					icon: <Sales />,
				},
				{
					name: 'Orders',
					count: (data as VMarketData)?.order_count ?? 0,
					icon: <Order />,
				},
				{
					name: 'Customers',
					count: (data as VMarketData)?.customers_count ?? 0,
					icon: <Customers />,
				},
				{
					name: 'Reactions',
					count: (data as VMarketData)?.likes_count ?? 0,
					icon: <Reactions />,
				},
				{
					name: 'Shares',
					count: (data as VMarketData)?.shares_count ?? 0,
					icon: <ShareMini />,
				},
				{
					name: 'Rating and Reviews',
					count: `${(data as VMarketData)?.rating ?? 0} (${(data as VMarketData)?.reviews_count ?? 0})`,
					icon: <RatingsIcon />,
				},
			],
		},
		'V-Guide': {
			profile: [
				{
					name: 'Sales',
					count: `$${formatNumber((data as VGuideData)?.counts?.sales_sum ?? 0)}`,
					icon: <Sales />,
				},
				{
					name: 'Likes',
					count: `${formatNumber((data as VGuideData)?.counts?.likes_count ?? 0)}`,
					icon: <LikeIcon />,
				},
				{
					name: 'Shares',
					count: `${formatNumber((data as VGuideData)?.counts?.shares_count ?? 0)}`,
					icon: <ShareIcon />,
				},
				{
					name: 'Endorsements',
					count: `${formatNumber((data as VGuideData)?.counts?.endorsements_count ?? 0)}`,
					icon: <EndorsementsGray />,
				},
			],
		},
	};

	return moduleStats[module]?.[type] || [];
};

export const MODULE_ASSETS_SHARE_LINKS: { [key: string]: string } = {
	socialPage: 'social-page/{id}/about',
	socialGroup: 'social-group/{id}/about',
	channel: 'channels/{id}/about',
	service: 'services/{id}/details',
	video: 'videos/{id}/details',
	store: 'stores/{id}/details',
	episode: 'episodes/{id}/details',
	blogPost: 'posts/{id}/details',
	jobPosting: 'jobs/{id}/details',
	events: 'v-events/{id}/details',
	sponsorship: 'sponsorships/{id}/details',
	book: 'books/{id}/details',
	podcast: 'podcasts/{id}/details',
	consultation: 'consultations/{id}/details',
	product: '{id}/details',
	webinar: 'webinars/{id}/details',
	course: 'courses/{id}/details',
	project: 'projects/{id}/details',
	socialGroupPost: 'social-group/{id}/timeline',
	socialPagePost: 'social-page/{id}/timeline',
	timelinePost: 'v-nation-author/{id}/timeline',
	user: '',
	endorsement: '',
	socialTimelinePost: '',
	videoChannel: '',
	client: '',
	freelancer: '',
	employer: '',
	candidate: '',
	room: '',
	conversation: '',
	roomMessage: '',
	message: '',
	cast: '',
	freelancingProject: '',
	freelancingService: '',
	blogpostInteraction: '',
	bookInteraction: '',
	sponsorshipInteraction: '',
	episodeInteraction: '',
	podcastInteraction: '',
	courseInteraction: '',
	courseDetailInteraction: '',
	courseUnitLessonInteraction: '',
	productInteraction: '',
	storeInteraction: '',
	eventInteraction: '',
	consultationInteraction: '',
	jobPostingInteraction: '',
	projectInteraction: '',
	serviceInteraction: '',
	socialGroupInteraction: '',
	socialGroupPostInteraction: '',
	socialPageInteraction: '',
	socialPagePostInteraction: '',
	socialTimelinePostInteraction: '',
	channelInteraction: '',
	playlistInteraction: '',
	videoInteraction: '',
	webinarInteraction: '',
	profileInteraction: '',
};
