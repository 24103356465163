import { SVGProps } from 'react';

const SocialPagesGradient = (props: SVGProps<SVGSVGElement>) => (
	<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_9_28954)">
			<path
				d="M1.5 5.49219H14.5"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.0625 7.46094H6.8125"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.0625 9.33594H4.625"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.375 9.33594H12.9375"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.0625 11.2109H4.625"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.375 11.2109H12.9375"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.1875 7.46094H12.9375"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.5 13.4922H1.5V2.49219H14.5V13.4922H11.5"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeMiterlimit={10}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8 12.2734C8.44873 12.2734 8.8125 11.9097 8.8125 11.4609C8.8125 11.0122 8.44873 10.6484 8 10.6484C7.55127 10.6484 7.1875 11.0122 7.1875 11.4609C7.1875 11.9097 7.55127 12.2734 8 12.2734Z"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.8125 10.6483V11.6639C8.8125 11.8256 8.8767 11.9805 8.99098 12.0948C9.10526 12.2091 9.26026 12.2733 9.42188 12.2733C9.58349 12.2733 9.73849 12.2091 9.85277 12.0948C9.96705 11.9805 10.0313 11.8256 10.0313 11.6639V11.4608C10.0312 11.0024 9.87611 10.5574 9.59114 10.1983C9.30616 9.83918 8.90808 9.58703 8.46163 9.48284C8.01518 9.37865 7.54661 9.42855 7.13212 9.62443C6.71763 9.82031 6.38158 10.1506 6.17863 10.5617C5.97568 10.9728 5.91776 11.4404 6.01429 11.8886C6.11081 12.3368 6.35611 12.7391 6.71029 13.0302C7.06447 13.3213 7.5067 13.484 7.96508 13.4919C8.42346 13.4998 8.87102 13.3524 9.235 13.0736"
				stroke={props.stroke || '#016DEA'} // use stroke from props
				strokeWidth={0.7}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_9_28954">
				<rect width={16} height={16} fill="white" transform="translate(0 -0.0078125)" />
			</clipPath>
		</defs>
	</svg>
);
export default SocialPagesGradient;
