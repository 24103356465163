import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Books = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_4603_25020)">
			<path
				d="M13.265 10.6672H5.26502C4.64504 10.6672 4.33505 10.6672 4.08072 10.7354C3.39053 10.9203 2.85144 11.4594 2.6665 12.1496"
				stroke={props.stroke || 'url(#paint0_linear_4603_25020)'}
			/>
			<path
				d="M4.6665 10.6672V1.66724"
				stroke={props.stroke || 'url(#paint1_linear_4603_25020)'}
				strokeLinecap="round"
			/>
			<path
				d="M6.6665 14.6671C4.78088 14.6671 3.83808 14.6671 3.25229 14.0813C2.6665 13.4955 2.6665 12.5527 2.6665 10.6671V5.33374C2.6665 3.44812 2.6665 2.50531 3.25229 1.91953C3.83808 1.33374 4.78088 1.33374 6.6665 1.33374H9.33317C11.2188 1.33374 12.1616 1.33374 12.7474 1.91953C13.3332 2.50531 13.3332 3.44812 13.3332 5.33374M9.33317 14.6671C11.2188 14.6671 12.1616 14.6671 12.7474 14.0813C13.3332 13.4955 13.3332 12.5527 13.3332 10.6671V8.00041"
				stroke={props.stroke || 'url(#paint2_linear_4603_25020)'}
				strokeLinecap="round"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_4603_25020"
				x1="6.81269"
				y1="12.0766"
				x2="7.54506"
				y2="9.34773"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_4603_25020"
				x1="5.05771"
				y1="10.2241"
				x2="6.08272"
				y2="10.1647"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_4603_25020"
				x1="6.83934"
				y1="14.0105"
				x2="16.1851"
				y2="10.1142"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_4603_25020">
				<rect width="16" height="16" fill="white" transform="translate(0 0.000488281)" />
			</clipPath>
		</defs>
	</svg>
);
export default Books;
