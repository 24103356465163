import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Sales = (props: SVGProps<SVGSVGElement>) => (
	<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.02412 1.20781C1.49912 1.97031 1.14912 4.10781 2.34912 5.35781C2.87412 5.90781 3.52412 6.19531 4.27412 6.19531C5.57412 6.18281 6.61162 5.37031 6.88662 4.13281C7.14912 2.93281 6.48662 1.57031 5.41162 1.12031C4.78662 0.857813 3.64912 0.895313 3.02412 1.20781ZM5.48662 1.92031C5.98662 2.29531 6.29912 2.92031 6.29912 3.57031C6.29912 5.49531 3.84912 6.29531 2.67412 4.74531C2.37412 4.35781 2.31162 4.15781 2.31162 3.55781C2.31162 2.78281 2.52412 2.38281 3.14912 1.90781C3.79912 1.42031 4.82412 1.43281 5.48662 1.92031Z"
			fill={props.fill || props.stroke || 'url(#paint0_linear_8575_130747)'}
		/>
		<path
			d="M4 1.94531C4 2.08281 3.925 2.19531 3.8375 2.19531C3.55 2.19531 3.25 2.63281 3.25 3.04531C3.25 3.45781 3.6125 3.82031 4.0375 3.82031C4.1625 3.82031 4.3875 3.88281 4.525 3.95781C4.775 4.09531 4.775 4.10781 4.575 4.25781C4.3 4.47031 3.9 4.35781 3.8125 4.05781C3.7625 3.93281 3.625 3.82031 3.4875 3.82031C3.3125 3.82031 3.25 3.90781 3.25 4.17031C3.25 4.52031 3.575 4.94531 3.8375 4.94531C3.9125 4.94531 4 5.05781 4.0375 5.19531C4.075 5.33281 4.2 5.44531 4.3125 5.44531C4.425 5.44531 4.55 5.33281 4.5875 5.19531C4.625 5.05781 4.7 4.94531 4.7625 4.94531C5 4.94531 5.375 4.42031 5.375 4.08281C5.375 3.65781 5.175 3.47031 4.475 3.27031C3.8875 3.09531 3.7375 2.88281 4.1 2.77031C4.425 2.65781 4.7125 2.79531 4.7875 3.08281C4.8625 3.37031 5.225 3.39531 5.3375 3.12031C5.4375 2.84531 5.1625 2.30781 4.8875 2.23281C4.75 2.19531 4.625 2.07031 4.5875 1.93281C4.5 1.60781 4 1.60781 4 1.94531Z"
			fill={props.fill || props.stroke || 'url(#paint1_linear_8575_130747)'}
		/>
		<path
			d="M9.83754 2.27205C9.65004 2.47205 9.75004 2.82205 10 2.82205C10.1375 2.82205 10.25 2.87205 10.25 2.93455C10.25 3.14705 7.51254 5.67205 6.21254 6.64705C5.47504 7.19705 4.13754 8.05955 3.25004 8.55955C1.71254 9.42205 1.37504 9.73455 1.80004 9.89705C2.03754 9.98455 3.98754 8.97205 5.45004 7.99705C7.05004 6.92205 8.35004 5.85955 9.66254 4.53455C10.3 3.88455 10.75 3.52205 10.75 3.63455C10.75 3.77205 10.8375 3.82205 11.0375 3.79705C11.2875 3.75955 11.3125 3.69705 11.3125 3.00955V2.25955L10.625 2.22205C10.2375 2.19705 9.88754 2.22205 9.83754 2.27205Z"
			fill={props.fill || props.stroke || 'url(#paint2_linear_8575_130747)'}
		/>
		<path
			d="M10.75 9.88281C10.75 13.8328 10.725 14.4453 10.5625 14.4453C10.4 14.4453 10.375 13.9328 10.35 10.9078L10.3125 7.38281L9.0375 7.34531L7.75 7.30781V10.8828C7.75 14.3578 7.75 14.4453 7.5 14.4453C7.2625 14.4453 7.25 14.3578 7.25 11.8828V9.30781L5.975 9.34531L4.6875 9.38281L4.65 11.9078C4.625 14.0203 4.5875 14.4453 4.4375 14.4453C4.2875 14.4453 4.25 14.1453 4.225 12.9078L4.1875 11.3828H2.9375H1.6875L1.65 12.9078L1.6125 14.4453H1.125C0.7125 14.4453 0.625 14.4828 0.625 14.6953C0.625 14.9453 0.7125 14.9453 7.5 14.9453C14.2875 14.9453 14.375 14.9453 14.375 14.6953C14.375 14.4828 14.2875 14.4453 13.875 14.4453H13.375V9.88281V5.32031H12.0625H10.75V9.88281ZM12.75 10.1953V14.4453H12.0625H11.375V10.1953V5.94531H12.0625H12.75V10.1953ZM9.75 11.1953V14.4453H9.0625H8.375V11.1953V7.94531H9.0625H9.75V11.1953ZM6.625 12.1953V14.4453H5.9375H5.25V12.1953V9.94531H5.9375H6.625V12.1953ZM3.625 13.1953V14.4453H2.9375H2.25V13.1953V11.9453H2.9375H3.625V13.1953Z"
			fill={props.fill || props.stroke || 'url(#paint3_linear_8575_130747)'}
		/>
		<defs>
			<linearGradient
				id="paint0_linear_8575_130747"
				x1="3.70821"
				y1="1.20439"
				x2="7.9841"
				y2="3.46058"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_8575_130747"
				x1="4.08131"
				y1="1.87549"
				x2="6.09188"
				y2="2.46848"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_8575_130747"
				x1="5.41837"
				y1="2.59092"
				x2="12.3792"
				y2="7.15785"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_8575_130747"
				x1="6.00405"
				y1="5.79427"
				x2="15.1026"
				y2="12.5678"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default Sales;
