import { SVGProps } from 'react';

const Applicants = (props: SVGProps<SVGSVGElement>) => (
	<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11.3337 14.001V12.6676C11.3337 11.9604 11.0527 11.2821 10.5526 10.782C10.0525 10.2819 9.37424 10.001 8.66699 10.001H3.33366C2.62641 10.001 1.94814 10.2819 1.44804 10.782C0.947944 11.2821 0.666992 11.9604 0.666992 12.6676V14.001"
			stroke={props.stroke || 'url(#paint0_linear_6375_131611)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.99967 7.33431C7.47243 7.33431 8.66634 6.1404 8.66634 4.66764C8.66634 3.19488 7.47243 2.00098 5.99967 2.00098C4.52692 2.00098 3.33301 3.19488 3.33301 4.66764C3.33301 6.1404 4.52692 7.33431 5.99967 7.33431Z"
			stroke={props.stroke || 'url(#paint1_linear_6375_131611)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.333 14.0012V12.6679C15.3326 12.077 15.1359 11.5031 14.7739 11.0361C14.4119 10.5691 13.9051 10.2356 13.333 10.0879"
			stroke={props.stroke || 'url(#paint2_linear_6375_131611)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.667 2.08789C11.2406 2.23476 11.749 2.56836 12.1121 3.0361C12.4752 3.50384 12.6722 4.07911 12.6722 4.67122C12.6722 5.26334 12.4752 5.83861 12.1121 6.30635C11.749 6.77409 11.2406 7.10769 10.667 7.25456"
			stroke={props.stroke || 'url(#paint3_linear_6375_131611)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_6375_131611"
				x1={4.83983}
				y1={13.804}
				x2={8.58238}
				y2={8.60307}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_6375_131611"
				x1={5.41943}
				y1={7.07168}
				x2={9.73304}
				y2={4.82374}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_6375_131611"
				x1={14.1154}
				y1={13.8085}
				x2={16.0361}
				y2={13.297}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_6375_131611"
				x1={11.4514}
				y1={7.00014}
				x2={13.4327}
				y2={6.59942}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default Applicants;
