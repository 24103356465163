const GradientMicIcon = () => {
	return (
		<>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6 6.66699C6 5.56243 6.8954 4.66699 8 4.66699C9.1046 4.66699 10 5.56243 10 6.66699V8.66699C10 9.77159 9.1046 10.667 8 10.667C6.8954 10.667 6 9.77159 6 8.66699V6.66699Z"
					stroke="url(#paint0_linear_5319_68868)"
				/>
				<path d="M8.66797 6.66699H10.0013" stroke="url(#paint1_linear_5319_68868)" strokeLinecap="round" />
				<path d="M8.66797 8.66699H10.0013" stroke="url(#paint2_linear_5319_68868)" strokeLinecap="round" />
				<path d="M6 6.66699H6.66667" stroke="url(#paint3_linear_5319_68868)" strokeLinecap="round" />
				<path d="M6 8.66699H6.66667" stroke="url(#paint4_linear_5319_68868)" strokeLinecap="round" />
				<path
					d="M1.33203 8.00033C1.33203 7.26393 1.92898 6.66699 2.66536 6.66699C3.40174 6.66699 3.9987 7.26393 3.9987 8.00033V9.33366C3.9987 10.0701 3.40174 10.667 2.66536 10.667C1.92898 10.667 1.33203 10.0701 1.33203 9.33366V8.00033Z"
					stroke="url(#paint5_linear_5319_68868)"
				/>
				<path
					d="M12 8.00033C12 7.26393 12.5969 6.66699 13.3333 6.66699C14.0697 6.66699 14.6667 7.26393 14.6667 8.00033V9.33366C14.6667 10.0701 14.0697 10.667 13.3333 10.667C12.5969 10.667 12 10.0701 12 9.33366V8.00033Z"
					stroke="url(#paint6_linear_5319_68868)"
				/>
				<path d="M8 10.667V12.667" stroke="url(#paint7_linear_5319_68868)" strokeLinecap="round" />
				<path
					d="M12 5.50879C11.6667 4.50879 10.4 2.50879 8 2.50879C5 2.50879 4 5.50879 4 5.50879"
					stroke="url(#paint8_linear_5319_68868)"
					strokeLinecap="round"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_5319_68868"
						x1="7.56481"
						y1="10.3715"
						x2="11.2356"
						y2="9.09625"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_5319_68868"
						x1="9.18957"
						y1="7.61775"
						x2="10.1144"
						y2="6.97517"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_5319_68868"
						x1="9.18957"
						y1="9.61775"
						x2="10.1144"
						y2="8.97517"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint3_linear_5319_68868"
						x1="6.2608"
						y1="7.61775"
						x2="6.87259"
						y2="7.4052"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint4_linear_5319_68868"
						x1="6.2608"
						y1="9.61775"
						x2="6.87259"
						y2="9.4052"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint5_linear_5319_68868"
						x1="2.37524"
						y1="10.47"
						x2="4.82241"
						y2="9.61983"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint6_linear_5319_68868"
						x1="13.0432"
						y1="10.47"
						x2="15.4904"
						y2="9.61983"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint7_linear_5319_68868"
						x1="8.3912"
						y1="12.5685"
						x2="9.35427"
						y2="12.3176"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint8_linear_5319_68868"
						x1="7.12963"
						y1="5.36106"
						x2="9.93654"
						y2="1.46036"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
				</defs>
			</svg>
		</>
	);
};

export default GradientMicIcon;
