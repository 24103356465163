import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Consultation = (props: SVGProps<SVGSVGElement>) => (
	<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_4365_242918)">
			<path
				d="M8.69905 0.947754H7.3009C3.45837 0.947754 0.332275 4.07384 0.332275 7.91638V8.19831V10.2899V11.679C0.332275 11.8947 0.507132 12.0695 0.722753 12.0695H0.878945H1.30886V12.3445C1.30886 12.5602 1.48372 12.735 1.69934 12.735H2.40798C3.10857 12.735 3.67852 12.165 3.67852 11.4644V8.41284C3.67852 7.71224 3.10857 7.1423 2.40798 7.1423H1.69934C1.48372 7.1423 1.30886 7.31708 1.30886 7.53278V7.80783H1.11596C1.1743 4.44605 3.92538 1.72871 7.3009 1.72871H8.69905C12.0745 1.72871 14.8257 4.44605 14.884 7.80791H14.6911V7.53278C14.6911 7.31708 14.5162 7.1423 14.3006 7.1423H13.5921C12.8915 7.1423 12.3215 7.71224 12.3215 8.41284V11.4646C12.3215 12.1652 12.8915 12.7351 13.5921 12.7351H14.3006C14.4088 12.7351 14.5066 12.6911 14.5773 12.62V13.4628C14.5773 14.5932 13.6577 15.5128 12.5273 15.5128H10.2056C10.0506 15.1299 9.6754 14.8588 9.23752 14.8588H8.18323C7.60727 14.8588 7.1387 15.3273 7.1387 15.9033C7.1387 16.4793 7.60727 16.9478 8.18323 16.9478H9.23752C9.6754 16.9478 10.0506 16.6767 10.2056 16.2938H12.5273C14.0883 16.2938 15.3583 15.0238 15.3583 13.4628V12.061C15.535 12.0237 15.6677 11.867 15.6677 11.6792V10.2901V8.19839V7.91646C15.6677 4.07384 12.5416 0.947754 8.69905 0.947754Z"
				fill="url(#paint0_linear_4365_242918)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_4365_242918"
				x1="6.33154"
				y1="16.1599"
				x2="18.9542"
				y2="9.85519"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_4365_242918">
				<rect width="16" height="16" fill="white" transform="translate(0 0.947754)" />
			</clipPath>
		</defs>
	</svg>
);

export default Consultation;
