import { SVGProps } from 'react';
const Comment = (props: SVGProps<SVGSVGElement>) => (
	<svg width={18} height={18} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<defs>
			<linearGradient id="commentGradient" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="0%" style={{ stopColor: '#016DEA' }} />
				<stop offset="100%" style={{ stopColor: '#FA0101' }} />
			</linearGradient>
		</defs>
		<path
			d="M14 7.65814C14.0023 8.53805 13.7967 9.40606 13.4 10.1915C12.9296 11.1326 12.2065 11.9243 11.3116 12.4777C10.4168 13.0311 9.3855 13.3244 8.33333 13.3248C7.45342 13.3271 6.58541 13.1215 5.8 12.7248L2 13.9915L3.26667 10.1915C2.86995 9.40606 2.66437 8.53805 2.66667 7.65814C2.66707 6.60598 2.96041 5.57471 3.51381 4.67984C4.06722 3.78497 4.85884 3.06185 5.8 2.59147C6.58541 2.19476 7.45342 1.98918 8.33333 1.99147H8.66667C10.0562 2.06814 11.3687 2.65465 12.3528 3.63871C13.3368 4.62278 13.9233 5.93524 14 7.32481V7.65814Z"
			stroke={props.stroke || 'url(#commentGradient)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.00002 8.32487C8.18412 8.32487 8.33335 8.17563 8.33335 7.99154C8.33335 7.80744 8.18412 7.6582 8.00002 7.6582C7.81593 7.6582 7.66669 7.80744 7.66669 7.99154C7.66669 8.17563 7.81593 8.32487 8.00002 8.32487Z"
			stroke={props.stroke || 'url(#commentGradient)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.3333 8.32487C10.5174 8.32487 10.6667 8.17563 10.6667 7.99154C10.6667 7.80744 10.5174 7.6582 10.3333 7.6582C10.1492 7.6582 10 7.80744 10 7.99154C10 8.17563 10.1492 8.32487 10.3333 8.32487Z"
			stroke={props.stroke || 'url(#commentGradient)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.66665 8.32487C5.85074 8.32487 5.99998 8.17563 5.99998 7.99154C5.99998 7.80744 5.85074 7.6582 5.66665 7.6582C5.48255 7.6582 5.33331 7.80744 5.33331 7.99154C5.33331 8.17563 5.48255 8.32487 5.66665 8.32487Z"
			stroke={props.stroke || 'url(#commentGradient)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export default Comment;
