type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

export const GroupMembers = (props: Props) => {
	const { width = 24, height = 24, color = '#016DEA', ...rest } = props;

	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect y="-0.00012207" width="40" height="40" rx="20" fill="#F4F4F4" />
			<g clip-path="url(#clip0_7247_757351)">
				<path
					d="M18.2018 11.8436C17.0206 12.4436 16.4018 13.3436 16.2893 14.6561C16.1768 15.9686 16.4956 16.8311 17.3768 17.6374C20.1518 20.1311 24.3518 17.6186 23.3956 14.0374C22.7956 11.8061 20.3393 10.7749 18.2018 11.8436ZM20.8081 12.5749C22.7206 13.3624 23.1331 15.6311 21.5956 16.9811C20.7518 17.7124 19.9268 17.8624 18.9143 17.4686C16.7581 16.6061 16.6268 13.7936 18.6893 12.7249C19.6456 12.2374 19.9831 12.2186 20.8081 12.5749Z"
					fill="#016DEA"
				/>
				<path
					d="M26.0944 14.0749C24.6319 14.7124 24.0132 16.3624 24.6694 17.8249C25.1569 18.9311 25.9632 19.4374 27.2382 19.4374C30.1632 19.4374 31.2132 15.6874 28.7007 14.2436C27.8757 13.7749 26.9382 13.6999 26.0944 14.0749ZM28.2132 15.1249C29.3382 15.8186 29.4882 16.9249 28.5507 17.8624C28.0632 18.3499 27.7632 18.4999 27.2194 18.4999C25.4007 18.4999 24.7257 15.9124 26.3382 15.0499C27.0319 14.6561 27.4819 14.6936 28.2132 15.1249Z"
					fill="#016DEA"
				/>
				<path
					d="M11.3009 14.4311C8.7884 15.8749 9.8384 19.6249 12.7634 19.6249C14.0384 19.6249 14.8446 19.1186 15.3321 18.0124C16.4759 15.4624 13.7384 13.0249 11.3009 14.4311ZM13.4009 15.1436C14.6384 15.5749 14.9759 17.1874 14.0196 18.1436C13.4196 18.7249 12.6884 18.8561 11.8821 18.4811C11.3946 18.2561 10.8134 17.4124 10.8134 16.9061C10.8134 16.3811 11.3571 15.5186 11.9009 15.2374C12.5196 14.8999 12.6696 14.8811 13.4009 15.1436Z"
					fill="#016DEA"
				/>
				<path
					d="M18.4071 19.1374C17.3571 19.3999 16.8133 19.6624 15.9133 20.3186C15.3133 20.7686 15.2946 20.7686 14.3383 20.4499C13.1571 20.0561 11.5633 20.1686 10.4946 20.7124C9.63209 21.1624 8.63834 22.2311 8.26334 23.1124C7.92584 23.8999 7.90709 26.7686 8.22584 27.0874C8.37584 27.2374 9.25709 27.3124 10.9446 27.3124C13.0446 27.3124 13.4383 27.3499 13.4383 27.5936C13.4383 27.7436 13.6071 28.0436 13.8133 28.2499C14.1696 28.6061 14.4321 28.6249 20.0008 28.6249C25.5696 28.6249 25.8321 28.6061 26.1883 28.2499C26.3946 28.0436 26.5633 27.7061 26.5633 27.4999C26.5633 27.1436 26.6946 27.1249 28.8696 27.1249C31.9258 27.1249 32.0008 27.0686 32.0008 24.9874C32.0008 22.5686 31.1008 21.1061 29.1133 20.2811C28.1946 19.8874 26.2821 19.8874 25.3071 20.2811C24.5571 20.5811 24.5571 20.5811 23.6008 19.9811C22.6258 19.3811 20.9008 18.8749 19.9071 18.8936C19.6071 18.8936 18.9321 19.0061 18.4071 19.1374ZM22.3071 20.4124C23.4696 20.9186 24.9321 22.5124 25.3446 23.7311C25.5508 24.3499 25.6258 25.1186 25.5883 26.1124L25.5321 27.5936H20.0008H14.4696L14.4133 26.1124C14.3383 24.2186 14.7133 23.1124 15.8196 21.8749C17.5821 19.9249 19.8883 19.3999 22.3071 20.4124ZM28.9821 21.3124C30.4258 22.0436 31.1383 23.3936 31.0258 25.1561L30.9696 26.0936L28.7946 26.1499L26.6196 26.2061L26.5258 24.8749C26.4508 23.8811 26.2821 23.2436 25.8508 22.4374L25.2883 21.3311L25.7758 21.1436C26.6571 20.8249 28.2133 20.8999 28.9821 21.3124ZM13.8508 21.3499C14.2446 21.4624 14.5446 21.6124 14.5071 21.6686C13.8883 22.7936 13.5508 23.8436 13.4758 25.0061L13.3821 26.3749H11.1696H8.93834V25.2311C8.93834 23.8999 9.25709 23.1311 10.1383 22.2686C11.1883 21.2561 12.4633 20.9374 13.8508 21.3499Z"
					fill="#016DEA"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7247_757351">
					<rect width="24" height="24" fill="white" transform="translate(8 7.99988)" />
				</clipPath>
			</defs>
		</svg>
	);
};
