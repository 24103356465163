const SubscriberIcon = () => {
	return (
		<div>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.668 14V12.6667C10.668 11.9594 10.387 11.2811 9.88692 10.781C9.38682 10.281 8.70855 10 8.0013 10H3.33464C2.62739 10 1.94911 10.281 1.44902 10.781C0.94892 11.2811 0.667969 11.9594 0.667969 12.6667V14"
					stroke="url(#paint0_linear_5319_68142)"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.66667 7.33333C7.13943 7.33333 8.33333 6.13943 8.33333 4.66667C8.33333 3.19391 7.13943 2 5.66667 2C4.19391 2 3 3.19391 3 4.66667C3 6.13943 4.19391 7.33333 5.66667 7.33333Z"
					stroke="url(#paint1_linear_5319_68142)"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.332 7.33333L12.6654 8.66667L15.332 6"
					stroke="url(#paint2_linear_5319_68142)"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_5319_68142"
						x1="4.58001"
						y1="13.803"
						x2="8.39284"
						y2="8.83559"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_5319_68142"
						x1="5.08642"
						y1="7.07071"
						x2="9.40003"
						y2="4.82276"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_5319_68142"
						x1="12.8968"
						y1="8.53535"
						x2="15.4504"
						y2="6.53929"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
};

export default SubscriberIcon;
