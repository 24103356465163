import React, { SVGProps } from 'react';

const ShareIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_7458_130210)">
			<path
				d="M12 5.33398C13.1046 5.33398 14 4.43855 14 3.33398C14 2.22941 13.1046 1.33398 12 1.33398C10.8954 1.33398 10 2.22941 10 3.33398C10 4.43855 10.8954 5.33398 12 5.33398Z"
				stroke={props.stroke || 'url(#paint0_linear_7458_130210)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 10.0006C5.10457 10.0006 6 9.10518 6 8.00061C6 6.89604 5.10457 6.00061 4 6.00061C2.89543 6.00061 2 6.89604 2 8.00061C2 9.10518 2.89543 10.0006 4 10.0006Z"
				stroke={props.stroke || 'url(#paint1_linear_7458_130210)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 14.6672C13.1046 14.6672 14 13.7718 14 12.6672C14 11.5627 13.1046 10.6672 12 10.6672C10.8954 10.6672 10 11.5627 10 12.6672C10 13.7718 10.8954 14.6672 12 14.6672Z"
				stroke={props.stroke || 'url(#paint2_linear_7458_130210)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.72656 9.00732L10.2799 11.6607"
				stroke={props.stroke || 'url(#paint3_linear_7458_130210)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.2732 4.34058L5.72656 6.99391"
				stroke={props.stroke || 'url(#paint4_linear_7458_130210)'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_7458_130210"
				x1="11.5648"
				y1="5.13701"
				x2="14.8"
				y2="3.45105"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_7458_130210"
				x1="3.56481"
				y1="9.80364"
				x2="6.80002"
				y2="8.11768"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_7458_130210"
				x1="11.5648"
				y1="14.4703"
				x2="14.8"
				y2="12.7843"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_7458_130210"
				x1="7.50784"
				y1="11.53"
				x2="10.1098"
				y2="9.20309"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_7458_130210"
				x1="7.50524"
				y1="6.86325"
				x2="10.1067"
				y2="4.54013"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_7458_130210">
				<rect width="16" height="16" fill="white" transform="translate(0 0.000610352)" />
			</clipPath>
		</defs>
	</svg>
);

export default ShareIcon;
