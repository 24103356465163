import React, { SVGProps } from 'react';

const VisitsIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			x="0.990234"
			y="1.55713"
			width="14.0438"
			height="12.9115"
			rx="1.34808"
			stroke="url(#paint0_linear_7463_130285)"
			strokeWidth="0.75"
		/>
		<path
			d="M2.47559 12.4829L3.27488 13.2822L4.07417 12.4829"
			stroke="url(#paint1_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.65951 5.22119H2.96891C2.59884 5.22119 2.29883 5.5212 2.29883 5.89128V10.5819C2.29883 10.952 2.59884 11.252 2.96891 11.252H7.65951C8.02959 11.252 8.3296 10.952 8.3296 10.5819V5.89128C8.3296 5.5212 8.02959 5.22119 7.65951 5.22119Z"
			stroke="url(#paint2_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.13986 7.56476C4.4172 7.56476 4.64203 7.33993 4.64203 7.06259C4.64203 6.78525 4.4172 6.56042 4.13986 6.56042C3.86252 6.56042 3.6377 6.78525 3.6377 7.06259C3.6377 7.33993 3.86252 7.56476 4.13986 7.56476Z"
			stroke="url(#paint3_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.3247 9.2381L6.65082 7.56421L2.96826 11.2468"
			stroke="url(#paint4_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="11.6733"
			y1="2.43201"
			x2="13.508"
			y2="2.43201"
			stroke="url(#paint5_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
		/>
		<line
			x1="9.45801"
			y1="5.23779"
			x2="13.2619"
			y2="5.23779"
			stroke="url(#paint6_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
		/>
		<line
			x1="9.45801"
			y1="6.71472"
			x2="13.2619"
			y2="6.71472"
			stroke="url(#paint7_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
		/>
		<line
			x1="9.45801"
			y1="8.19165"
			x2="13.2619"
			y2="8.19165"
			stroke="url(#paint8_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
		/>
		<line
			x1="9.45801"
			y1="12.8688"
			x2="11.2926"
			y2="12.8688"
			stroke="url(#paint9_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
		/>
		<line
			x1="5.76562"
			y1="12.8688"
			x2="7.60024"
			y2="12.8688"
			stroke="url(#paint10_linear_7463_130285)"
			strokeWidth="0.75"
			strokeLinecap="round"
		/>
		<circle cx="2.48613" cy="2.80657" r="0.393846" fill="url(#paint11_linear_7463_130285)" />
		<circle cx="3.59355" cy="2.80669" r="0.393846" fill="url(#paint12_linear_7463_130285)" />
		<circle cx="4.70146" cy="2.80669" r="0.393846" fill="url(#paint13_linear_7463_130285)" />
		<line
			x1="0.615234"
			y1="3.88367"
			x2="15.4091"
			y2="3.88367"
			stroke="url(#paint14_linear_7463_130285)"
			strokeWidth="0.75"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_7463_130285"
				x1="6.40264"
				y1="14.1709"
				x2="17.9425"
				y2="7.65877"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_7463_130285"
				x1="3.10096"
				y1="13.2428"
				x2="3.88899"
				y2="12.4215"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_7463_130285"
				x1="4.65809"
				y1="10.955"
				x2="9.53579"
				y2="8.41308"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_7463_130285"
				x1="4.03059"
				y1="7.5153"
				x2="4.8429"
				y2="7.09199"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_7463_130285"
				x1="5.06372"
				y1="11.0654"
				x2="8.56235"
				y2="8.41344"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_7463_130285"
				x1="12.3095"
				y1="3.75776"
				x2="13.254"
				y2="2.48553"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_7463_130285"
				x1="10.8645"
				y1="6.56355"
				x2="11.5707"
				y2="4.88762"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_7463_130285"
				x1="10.8645"
				y1="8.04048"
				x2="11.5707"
				y2="6.36455"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_7463_130285"
				x1="10.8645"
				y1="9.51741"
				x2="11.5707"
				y2="7.84148"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint9_linear_7463_130285"
				x1="10.0941"
				y1="14.1945"
				x2="11.0387"
				y2="12.9223"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint10_linear_7463_130285"
				x1="6.40174"
				y1="14.1945"
				x2="7.34629"
				y2="12.9223"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint11_linear_7463_130285"
				x1="2.40043"
				y1="3.16162"
				x2="3.03752"
				y2="2.82962"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint12_linear_7463_130285"
				x1="3.50786"
				y1="3.16175"
				x2="4.14494"
				y2="2.82974"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint13_linear_7463_130285"
				x1="4.61577"
				y1="3.16175"
				x2="5.25285"
				y2="2.82974"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint14_linear_7463_130285"
				x1="6.40264"
				y1="5.20942"
				x2="6.65439"
				y2="3.26857"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);

export default VisitsIcon;
