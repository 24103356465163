import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Members = (props: SVGProps<SVGSVGElement>) => (
	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_8575_130908)">
			<path
				d="M7.29925 3.50742C6.51175 3.90742 6.09925 4.50742 6.02425 5.38242C5.94925 6.25742 6.16175 6.83242 6.74925 7.36992C8.59925 9.03242 11.3993 7.35742 10.7618 4.96992C10.3618 3.48242 8.72425 2.79492 7.29925 3.50742ZM9.03675 3.99492C10.3118 4.51992 10.5868 6.03242 9.56175 6.93242C8.99925 7.41992 8.44925 7.51992 7.77425 7.25742C6.33675 6.68242 6.24925 4.80742 7.62425 4.09492C8.26175 3.76992 8.48675 3.75742 9.03675 3.99492Z"
				stroke={props.stroke}
				fill={(props.fill != 'none' && props.fill) || 'url(#paint0_linear_8575_130908)'}
			/>
			<path
				d="M12.5616 4.99531C11.5866 5.42031 11.1741 6.52031 11.6116 7.49531C11.9366 8.23281 12.4741 8.57031 13.3241 8.57031C15.2741 8.57031 15.9741 6.07031 14.2991 5.10781C13.7491 4.79531 13.1241 4.74531 12.5616 4.99531ZM13.9741 5.69531C14.7241 6.15781 14.8241 6.89531 14.1991 7.52031C13.8741 7.84531 13.6741 7.94531 13.3116 7.94531C12.0991 7.94531 11.6491 6.22031 12.7241 5.64531C13.1866 5.38281 13.4866 5.40781 13.9741 5.69531Z"
				stroke={props.stroke}
				fill={(props.fill != 'none' && props.fill) || 'url(#paint1_linear_8575_130908)'}
			/>
			<path
				d="M2.7006 5.23281C1.0256 6.19531 1.7256 8.69531 3.6756 8.69531C4.5256 8.69531 5.0631 8.35781 5.3881 7.62031C6.1506 5.92031 4.3256 4.29531 2.7006 5.23281ZM4.1006 5.70781C4.9256 5.99531 5.1506 7.07031 4.5131 7.70781C4.1131 8.09531 3.6256 8.18281 3.0881 7.93281C2.7631 7.78281 2.3756 7.22031 2.3756 6.88281C2.3756 6.53281 2.7381 5.95781 3.1006 5.77031C3.5131 5.54531 3.6131 5.53281 4.1006 5.70781Z"
				stroke={props.stroke}
				fill={(props.fill != 'none' && props.fill) || 'url(#paint2_linear_8575_130908)'}
			/>
			<path
				d="M7.43741 8.37031C6.73741 8.54531 6.37491 8.72031 5.77491 9.15781C5.37491 9.45781 5.36241 9.45781 4.72491 9.24531C3.93741 8.98281 2.87491 9.05781 2.16241 9.42031C1.58741 9.72031 0.924911 10.4328 0.674911 11.0203C0.449911 11.5453 0.437411 13.4578 0.649911 13.6703C0.749911 13.7703 1.33741 13.8203 2.46241 13.8203C3.86241 13.8203 4.12491 13.8453 4.12491 14.0078C4.12491 14.1078 4.23741 14.3078 4.37491 14.4453C4.61241 14.6828 4.78741 14.6953 8.49991 14.6953C12.2124 14.6953 12.3874 14.6828 12.6249 14.4453C12.7624 14.3078 12.8749 14.0828 12.8749 13.9453C12.8749 13.7078 12.9624 13.6953 14.4124 13.6953C16.4499 13.6953 16.4999 13.6578 16.4999 12.2703C16.4999 10.6578 15.8999 9.68281 14.5749 9.13281C13.9624 8.87031 12.6874 8.87031 12.0374 9.13281C11.5374 9.33281 11.5374 9.33281 10.8999 8.93281C10.2499 8.53281 9.09991 8.19531 8.43741 8.20781C8.23741 8.20781 7.78741 8.28281 7.43741 8.37031ZM10.0374 9.22031C10.8124 9.55781 11.7874 10.6203 12.0624 11.4328C12.1999 11.8453 12.2499 12.3578 12.2249 13.0203L12.1874 14.0078H8.49991H4.81241L4.77491 13.0203C4.72491 11.7578 4.97491 11.0203 5.71241 10.1953C6.88741 8.89531 8.42491 8.54531 10.0374 9.22031ZM14.4874 9.82031C15.4499 10.3078 15.9249 11.2078 15.8499 12.3828L15.8124 13.0078L14.3624 13.0453L12.9124 13.0828L12.8499 12.1953C12.7999 11.5328 12.6874 11.1078 12.3999 10.5703L12.0249 9.83281L12.3499 9.70781C12.9374 9.49531 13.9749 9.54531 14.4874 9.82031ZM4.39991 9.84531C4.66241 9.92031 4.86241 10.0203 4.83741 10.0578C4.42491 10.8078 4.19991 11.5078 4.14991 12.2828L4.08741 13.1953H2.61241H1.12491V12.4328C1.12491 11.5453 1.33741 11.0328 1.92491 10.4578C2.62491 9.78281 3.47491 9.57031 4.39991 9.84531Z"
				stroke={props.stroke}
				fill={(props.fill != 'none' && props.fill) || 'url(#paint3_linear_8575_130908)'}
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_8575_130908"
				x1="7.90495"
				y1="3.46174"
				x2="11.8127"
				y2="5.51093"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_8575_130908"
				x1="12.9258"
				y1="5.02011"
				x2="15.978"
				y2="6.64069"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_8575_130908"
				x1="3.24791"
				y1="5.1419"
				x2="6.30341"
				y2="6.76469"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_8575_130908"
				x1="6.75804"
				y1="8.52695"
				x2="12.9634"
				y2="16.5029"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_8575_130908">
				<rect width="16" height="16" fill="white" transform="translate(0.5 0.945312)" />
			</clipPath>
		</defs>
	</svg>
);
export default Members;
