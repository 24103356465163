import React, { SVGProps } from 'react';

const CommentIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg
		width={props.width || 16}
		height={props.height || 16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M14 7.6673C14.0023 8.54721 13.7967 9.41522 13.4 10.2006C12.9296 11.1418 12.2065 11.9334 11.3116 12.4868C10.4168 13.0402 9.3855 13.3336 8.33333 13.334C7.45342 13.3363 6.58541 13.1307 5.8 12.734L2 14.0006L3.26667 10.2006C2.86995 9.41522 2.66437 8.54721 2.66667 7.6673C2.66707 6.61513 2.96041 5.58386 3.51381 4.689C4.06722 3.79413 4.85884 3.07101 5.8 2.60063C6.58541 2.20392 7.45342 1.99834 8.33333 2.00063H8.66667C10.0562 2.07729 11.3687 2.6638 12.3528 3.64787C13.3368 4.63193 13.9233 5.9444 14 7.33396V7.6673Z"
			stroke={props.stroke || 'url(#paint0_linear_7458_130166)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.99984 8.3339C8.18393 8.3339 8.33317 8.18466 8.33317 8.00057C8.33317 7.81647 8.18393 7.66724 7.99984 7.66724C7.81574 7.66724 7.6665 7.81647 7.6665 8.00057C7.6665 8.18466 7.81574 8.3339 7.99984 8.3339Z"
			stroke={props.stroke || 'url(#paint1_linear_7458_130166)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.3333 8.3339C10.5174 8.3339 10.6667 8.18466 10.6667 8.00057C10.6667 7.81647 10.5174 7.66724 10.3333 7.66724C10.1492 7.66724 10 7.81647 10 8.00057C10 8.18466 10.1492 8.3339 10.3333 8.3339Z"
			stroke={props.stroke || 'url(#paint2_linear_7458_130166)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.66683 8.3339C5.85092 8.3339 6.00016 8.18466 6.00016 8.00057C6.00016 7.81647 5.85092 7.66724 5.66683 7.66724C5.48273 7.66724 5.3335 7.81647 5.3335 8.00057C5.3335 8.18466 5.48273 8.3339 5.66683 8.3339Z"
			stroke={props.stroke || 'url(#paint3_linear_7458_130166)'}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_7458_130166"
				x1="6.69445"
				y1="13.4097"
				x2="16.4001"
				y2="8.35183"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_7458_130166"
				x1="7.92731"
				y1="8.30107"
				x2="8.46651"
				y2="8.02008"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_7458_130166"
				x1="10.2608"
				y1="8.30107"
				x2="10.8"
				y2="8.02008"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_7458_130166"
				x1="5.5943"
				y1="8.30107"
				x2="6.1335"
				y2="8.02008"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);

export default CommentIcon;
