import { SVGProps } from 'react';
const TaggedIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={16} height={16} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={20} cy={19.9922} r={19.5} fill="white" stroke="url(#paint0_linear_2304_410622)" />
		<path
			d="M24 29.0098V27.0098C24 25.9489 23.5786 24.9315 22.8284 24.1813C22.0783 23.4312 21.0609 23.0098 20 23.0098H13C11.9391 23.0098 10.9217 23.4312 10.1716 24.1813C9.42143 24.9315 9 25.9489 9 27.0098V29.0098"
			stroke="url(#paint1_linear_2304_410622)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.5 19.0098C18.7091 19.0098 20.5 17.2189 20.5 15.0098C20.5 12.8006 18.7091 11.0098 16.5 11.0098C14.2909 11.0098 12.5 12.8006 12.5 15.0098C12.5 17.2189 14.2909 19.0098 16.5 19.0098Z"
			stroke="url(#paint2_linear_2304_410622)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M30.7573 17.7722L27.7654 20.7649C27.6879 20.8425 27.5958 20.9041 27.4945 20.9461C27.3932 20.9881 27.2846 21.0098 27.1749 21.0098C27.0653 21.0098 26.9567 20.9881 26.8553 20.9461C26.754 20.9041 26.662 20.8425 26.5845 20.7649L23 17.1837V13.0098H27.1728L30.7573 16.5952C30.9128 16.7516 31 16.9632 31 17.1837C31 17.4042 30.9128 17.6158 30.7573 17.7722Z"
			stroke="url(#paint3_linear_2304_410622)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M25.0898 15.0957H25.094"
			stroke="url(#paint4_linear_2304_410622)"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_2304_410622"
				x1={15.6481}
				y1={38.0225}
				x2={48.0002}
				y2={21.1629}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_2304_410622"
				x1={14.8681}
				y1={28.7143}
				x2={20.5873}
				y2={21.2631}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_2304_410622"
				x1={15.6296}
				y1={18.6158}
				x2={22.1}
				y2={15.2439}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_2304_410622"
				x1={26.1296}
				y1={20.6158}
				x2={32.6}
				y2={17.2439}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_2304_410622"
				x1={25.0915}
				y1={16.0465}
				x2={25.0958}
				y2={16.0465}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default TaggedIcon;
