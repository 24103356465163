import Button from '@/components/Button';
import Group from '@/components/icons/admin/Group';
import { ChevronIconx } from '@/components/icons/ChevronIconx';
import PageFollowers from '@/components/icons/PageFollowers';
import { GroupMembers } from '@/components/icons/v-nation/GroupMembers';
import { _privacyOptions, assetTableHeaders, MODULE_SHARE_LINKS } from '@/constants/constants';
import { updateWebinarEventStatus } from '@/firebase/webinars';
import { cn } from '@/lib/utils';
import { PaginationItemRenderProps, PaginationItemType } from '@nextui-org/react';
import { TypedLazyQueryTrigger } from '@reduxjs/toolkit/query/react';
import { Dispatch, SetStateAction } from 'react';
import { formatDate } from './common';
import { formatDateWithShortMonth } from './dateHelpers';
import { MODULE_ASSETS_SHARE_LINKS } from './shareModalConst';
import {
	AssetType,
	CountdownTime,
	Feature,
	MediaPostType,
	PrivacyOptions as PrivacyType,
	ProjectContractSummaryType,
	ProjectContractType,
	ServiceContractSummaryType,
	ServiceContractType,
	SponsorshipContractSummaryType,
	SponsorshipContractType,
	UserType,
	WebinarHandoutType,
	WebinarOfferType,
	WebinarPollType,
	WebinarType,
} from './types';

/**
 * Formats a number to a more readable string format, supporting various styles including currency and plain number formats with optional K (thousand) abbreviations.
 * @param number The number to format.
 * @param formatType The format type: 'currency', 'currencyWithComma', or 'plain'. Defaults to 'currency'.
 * @returns A formatted string based on the specified format type.
 */
export const formatNumber = (
	number: number,
	formatType: 'currency' | 'currencyWithComma' | 'plain' = 'currency'
): string => {
	if (number >= 1000) {
		const thousands = number / 1000;
		const roundedThousands = Math.round(thousands * 10) / 10; // Round to nearest tenth
		let suffix = `${roundedThousands}`; // Initialize suffix without 'K'
		if (roundedThousands % 1 === 0) {
			suffix = `${roundedThousands}K`; // Append 'K' for whole numbers
		} else {
			suffix = `${roundedThousands.toFixed(1)}K`; // Append 'K' with one decimal place
		}
		switch (formatType) {
			case 'currency':
				return `$${suffix}`;
			case 'currencyWithComma':
				return `$${number.toLocaleString()}`;
			case 'plain':
				return `${suffix}`;
			default:
				return `$${suffix}`;
		}
	} else {
		switch (formatType) {
			case 'currency':
			case 'currencyWithComma':
				return `$${number}`;
			case 'plain':
				return `${number}`;
			default:
				return `$${number}`;
		}
	}
};

export const formatCounts = (count: number) => {
	if (count >= 1_000_000) {
		return `${(count / 1_000_000).toFixed(1)}M`;
	} else if (count >= 1_000) {
		return `${(count / 1_000).toFixed(1)}K`;
	}
	return count % 1 === 0 ? count : count.toFixed(1);
};

export function downloadFile(fileUrl: string) {
	fetch(fileUrl)
		.then((response) => {
			// Check if the response is successful (status 200-299)
			if (!response.ok) {
				throw new Error(`Failed to fetch: ${response.statusText}`);
			}
			return response.blob(); // Convert the response to a Blob
		})
		.then((blob) => {
			const blobURL = window.URL.createObjectURL(new Blob([blob])); // Create an object URL for the Blob
			const fileName = fileUrl.split('/').pop(); // Extract the file name from the URL

			if (!fileName) {
				throw new Error('File name could not be determined.');
			}

			const aTag = document.createElement('a'); // Create an anchor tag for downloading
			aTag.href = blobURL;
			aTag.setAttribute('download', fileName); // Set the download attribute to the file name
			document.body.appendChild(aTag);
			aTag.click(); // Trigger the download
			aTag.remove(); // Remove the anchor tag after the download is triggered

			// Revoke the object URL to free up memory
			window.URL.revokeObjectURL(blobURL);
		})
		.catch((error) => {
			console.error('Error downloading file:', error);
		});
}

export const renderPaginationItem = ({
	ref,
	key,
	value,
	isActive,
	onNext,
	onPrevious,
	setPage,
	className,
}: PaginationItemRenderProps) => {
	if (value === PaginationItemType.NEXT) {
		return (
			<button
				key={key}
				className={cn(className, 'h-8 w-8 min-w-8 bg-default-200/50', 'hover:bg-default-200')}
				onClick={onNext}
			>
				<ChevronIconx className="rotate-180" />
			</button>
		);
	}

	if (value === PaginationItemType.PREV) {
		return (
			<button
				key={key}
				className={cn(className, 'h-8 w-8 min-w-8 bg-default-200/50', 'hover:bg-default-200')}
				onClick={onPrevious}
			>
				<ChevronIconx />
			</button>
		);
	}

	if (value === PaginationItemType.DOTS) {
		return (
			<button key={key} className={className}>
				...
			</button>
		);
	}

	return (
		<button
			ref={ref}
			key={key}
			className={cn(
				className,
				isActive && ['font-bold text-white', 'bg-[linear-gradient(62deg,#016dea_27.41%,#fa0101_95.02%)]']
			)}
			onClick={() => setPage(value)}
		>
			{value}
		</button>
	);
};

export function getWebinarQuantity(features: Feature[]): number {
	const webinarFeature = features.find(
		(feature) => feature.module_enum === 'V-Webinars' && feature.name === 'license Price/Host'
	);
	return webinarFeature ? webinarFeature.quantity ?? 0 : 0;
}

export const getAssetLockType = (assetType?: AssetType | string | null) => {
	switch (assetType) {
		case AssetType.PERSONAL:
			return 'user';
		case AssetType.ENTITY:
			return 'user';
		case AssetType.BLOG_POST:
			return 'blog post';
		case AssetType.SOCIAL_POST:
			return 'social page post';
		case AssetType.HIGHLIGHT:
			return 'social page post';
		case AssetType.REEL:
			return 'social page post';
		case AssetType.STORY:
			return 'social page post';
		case AssetType.SHORT:
			return 'video';
		case AssetType.SOCIAL_PAGE:
			return 'social page';
		case AssetType.SOCIAL_GROUP:
			return 'social group';
		case AssetType.CHANNEL:
			return 'video channel';
		case AssetType.VIDEO:
			return 'video';
		case AssetType.JOB:
			return 'job posting';
		case AssetType.SERVICE:
			return 'Freelancing_service';
		case AssetType.PROJECT:
			return 'Freelancing_project';
		case AssetType.COURSE:
			return 'course';
		case AssetType.STORE:
			return 'store';
		case AssetType.PHYSICAL_PRODUCT:
			return 'product';
		case AssetType.DIGITAL_PRODUCT:
			return 'product';
		case AssetType.EVENT:
			return 'events';
		case AssetType.WEBINAR:
			return 'webinar';
		case AssetType.CONSULTATION:
			return 'consultation';
		case AssetType.SPONSORSHIP:
			return 'sponsorship';
		case AssetType.PODCAST:
			return 'cast';
		case AssetType.EPISODE:
			return 'episode';
		case AssetType.HARDCOVER_BOOK:
			return 'book';
		case AssetType.E_BOOK:
			return 'book';
		default:
			return 'blog post';
	}
};

export const getAssetsTableData = (
	assetType?: AssetType | string | null,
	data?: any[],
	lockFunc?: ({ id, name }: { id: string | number; name: string }) => void,
	isLockLoading?: boolean,
	isLockError?: boolean,
	createChat?: (user: UserType) => void
): { headers: string[]; cells: string[][] } => {
	if (!assetType)
		return {
			headers: [],
			cells: [],
		};
	if (!data?.length)
		return {
			headers: assetTableHeaders[assetType],
			cells: [],
		};

	const assetLockType = getAssetLockType(assetType);

	const getActionsCell = (otherUser: UserType) => (
		<div className="flex space-x-1">
			<Button
				title={[AssetType.PERSONAL, AssetType.ENTITY].includes(assetType as AssetType) ? 'Lock Account' : 'Lock Asset'}
				onClick={() => lockFunc?.({ id: otherUser.id, name: assetLockType })}
				disabled={isLockLoading || isLockError}
			/>
			<Button
				title="Message Owner"
				startIcon={<Group />}
				onClick={() => {
					createChat?.(otherUser);
				}}
			/>
		</div>
	);
	const cells: any[][] = [];
	switch (assetType) {
		case AssetType.PERSONAL:
			{
				data.forEach((user: any) => {
					cells.push([
						getActionsCell(user),
						user.custom_id,
						user.referral_code,
						user.image,
						user.profile.legal_name,
						user.profile.nationality,
						user.profile.date_of_birth,
						user.profile.gender,
						user.nationality_id,
						user.nationality_id_file,
						user.referring_code,
						user.profile.public_name,
						user.profile.residence_country,
						user.profile.residence_city,
						user.time_zone,
						formatDate(new Date(user.joining_date), false, true),
						formatDate(new Date(user.last_seen_at), false, true),
						user.profile.phone,
						user.profile.whatsapp,
						user.profile.email,
						user.profile.website,
						user.plan_name,
						user.is_v_1k,
						user.counts.likes_count,
						user.counts.shares_count,
						user.counts.endorsements_count,
						user.counts.no_of_assets,
						user.counts.sales_sum,
						user.counts.earned_sum,
						user.counts.invested_sum,
						user.counts.reactions_count,
						user.counts.shares_count,
						user.counts.rating_avg,
						user.counts.reviews_count,
						user.counts.connections_count,
						user.counts.followers_count,
						user.counts.members_count,
						user.counts.subscribers_count,
						user.counts.customers_count,
						user.counts.hirings_count,
						user.counts.open_vacancies,
						user.profile.is_open_to_work ? 'Yes' : 'No',
						user.profile.social_status_enum ? 'Yes' : 'No',
						user.profile.military_status_enum,
						user.profile.employment_status,
						user.profile.total_years_of_experience,
						user.profile.excepected_gross_monthly_salary,
						user.profile.notice_period,
						user.profile.own_a_car ? 'Yes' : 'No',
						user.profile.willing_to_relocate ? 'Yes' : 'No',
						user.profile.weekly_hours_availability,
						user.profile.hourly_rate,
						user.success_rate,
						user.hiring_rate,
						user.open_projects,
					]);
				});
			}
			break;
		case AssetType.ENTITY:
			{
				data.forEach((user: any) => {
					cells.push([
						getActionsCell(user),
						user.custom_id,
						user.referral_code,
						user.image,
						user.profile.legal_name,
						user.profile.nationality,
						user.profile.date_of_birth,
						user.profile.gender,
						user.nationality_id,
						user.nationality_id_file,
						user.referring_code,
						user.profile.public_name,
						user.profile.residence_country,
						user.profile.residence_city,
						user.time_zone,
						formatDate(new Date(user.joining_date), false, true),
						formatDate(new Date(user.last_seen_at), false, true),
						user.profile.phone,
						user.profile.whatsapp,
						user.profile.email,
						user.profile.website,
						user.plan_name,
						user.is_v_1k,
						user.counts.likes_count,
						user.counts.shares_count,
						user.counts.endorsements_count,
						user.counts.no_of_assets,
						user.counts.sales_sum,
						user.counts.earned_sum,
						user.counts.invested_sum,
						user.counts.reactions_count,
						user.counts.shares_count,
						user.counts.rating_avg,
						user.counts.reviews_count,
						user.counts.connections_count,
						user.counts.followers_count,
						user.counts.members_count,
						user.counts.subscribers_count,
						user.counts.customers_count,
						user.counts.hirings_count,
						user.counts.open_vacancies,
						user.profile.is_open_to_work ? 'Yes' : 'No',
						user.profile.social_status_enum ? 'Yes' : 'No',
						user.profile.military_status_enum,
						user.profile.employment_status,
						user.profile.total_years_of_experience,
						user.profile.excepected_gross_monthly_salary,
						user.profile.notice_period,
						user.profile.own_a_car ? 'Yes' : 'No',
						user.profile.willing_to_relocate ? 'Yes' : 'No',
						user.profile.weekly_hours_availability,
						user.profile.hourly_rate,
						user.success_rate,
						user.hiring_rate,
						user.open_projects,
					]);
				});
			}
			break;
		case AssetType.BLOG_POST:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.SOCIAL_POST:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
						]);
					}
				);
			}
			break;
		case AssetType.HIGHLIGHT:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
						]);
					}
				);
			}
			break;
		case AssetType.REEL:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
						]);
					}
				);
			}
			break;
		case AssetType.STORY:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
						]);
					}
				);
			}
			break;
		case AssetType.SHORT:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
						]);
					}
				);
			}
			break;
		case AssetType.SOCIAL_PAGE:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						posts_count,
						followers_count,
						rating,
						reviews_count,
						shares_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							posts_count,
							followers_count,
							rating,
							reviews_count,
							shares_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.SOCIAL_GROUP:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						posts_count,
						members_count,
						rating,
						reviews_count,
						shares_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							posts_count,
							members_count,
							rating,
							reviews_count,
							shares_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.CHANNEL:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						videos_count,
						subscribers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							videos_count,
							subscribers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.VIDEO:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.JOB:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						interviewing_candidates,
						application_deadline,
						department,
						role_level,
						salary,
						payment_cycle_enum,
						contract_type_enum,
						work_type_enum,
						applicants_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							interviewing_candidates,
							application_deadline,
							department.name,
							role_level,
							salary,
							payment_cycle_enum.charAt(0).toUpperCase() + payment_cycle_enum.slice(1),
							contract_type_enum
								.split(' ')
								.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
								.join(''),
							work_type_enum.charAt(0).toUpperCase() + work_type_enum.slice(1),
							applicants_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.SERVICE:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						tier_price,
						sales,
						service_order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							tier_price,
							sales,
							service_order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.PROJECT:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						interviewing_candidates,
						application_deadline,
						budget_enum,
						budget_value,
						rate,
						deadline,
						work_type_enum,
						applicants_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							interviewing_candidates,
							application_deadline,
							budget_enum.charAt(0).toUpperCase() + budget_enum.slice(1),
							budget_value,
							rate,
							formatDate(new Date(deadline), false, true),
							work_type_enum.charAt(0).toUpperCase() + work_type_enum.slice(1),
							applicants_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.COURSE:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.STORE:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						products_count,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							products_count,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.PHYSICAL_PRODUCT:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.DIGITAL_PRODUCT:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.EVENT:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						status_enum,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							status_enum === 'coming' ? 'Yes' : 'No',
							status_enum === 'Ongoing' ? 'Yes' : 'No',
							status_enum === 'happened' ? 'Yes' : 'No',
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.WEBINAR:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						happening_status,
						webinar_type,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							happening_status === 'coming' ? 'Yes' : 'No',
							happening_status === 'happening' ? 'Yes' : 'No',
							happening_status === 'happened' ? 'Yes' : 'No',
							webinar_type === 'live' ? 'Yes' : 'No',
							webinar_type === 'hybrid' ? 'Yes' : 'No',
							webinar_type === 'automated' ? 'Yes' : 'No',
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.CONSULTATION:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.SPONSORSHIP:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						tier_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							tier_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.PODCAST:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.EPISODE:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.HARDCOVER_BOOK:
			{
				data.forEach(
					({
						id,
						custom_id,
						user,
						created_at = null,
						updated_at = null,
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured,
						is_exclusive,
					}: any) => {
						cells.push([
							getActionsCell(id),
							custom_id,
							user.full_name,
							formatDate(new Date(created_at), false, true),
							formatDate(new Date(updated_at), false, true),
							regular_price,
							sales,
							order_count,
							customers_count,
							views_count,
							comments_count,
							likes_count,
							shares_count,
							rating,
							reviews_count,
							is_featured ? 'Yes' : 'No',
							is_exclusive ? 'Yes' : 'No',
						]);
					}
				);
			}
			break;
		case AssetType.E_BOOK: {
			data.forEach(
				({
					id,
					custom_id,
					user,
					created_at = null,
					updated_at = null,
					regular_price,
					sales,
					order_count,
					customers_count,
					views_count,
					comments_count,
					likes_count,
					shares_count,
					rating,
					reviews_count,
					is_featured,
					is_exclusive,
				}: any) => {
					cells.push([
						getActionsCell(id),
						custom_id,
						user.full_name,
						formatDate(new Date(created_at), false, true),
						formatDate(new Date(updated_at), false, true),
						regular_price,
						sales,
						order_count,
						customers_count,
						views_count,
						comments_count,
						likes_count,
						shares_count,
						rating,
						reviews_count,
						is_featured ? 'Yes' : 'No',
						is_exclusive ? 'Yes' : 'No',
					]);
				}
			);
		}
	}
	return { headers: assetTableHeaders[assetType], cells };
};

// CONTRACTS

// SERVICE CONTRACTS
// MAP THE SERVICE CONTRACTS TO SUMMARY CONTRACTS
export const mappingContracts = (contracts: ServiceContractType[]): ServiceContractSummaryType[] => {
	return contracts.map((contract: ServiceContractType) => {
		return {
			orderId: contract.id,
			tier: contract.tier.name,
			soldBy: contract.seller.full_name,
			soldTo: contract.customer.full_name,
			price: '$' + contract.price,
			deliveryDate: formatDateWithShortMonth(contract.delivery_date),
			contractStatus: contract.status_enum,
			created_at: formatDateWithShortMonth(contract.created_at),
			service_title: contract.service_title,
		};
	});
};
// SPONSORSHIP CONTRACTS
// MAP THE SPONSORSHIP CONTRACTS TO SUMMARY CONTRACTS
export const mappingSponsorshipContracts = (contracts: SponsorshipContractType[]): SponsorshipContractSummaryType[] => {
	return contracts.map((contract: SponsorshipContractType) => {
		return {
			orderId: contract.id,
			tier: contract.tier.tier_name,
			soldBy: contract.seller.full_name,
			soldTo: contract.customer.full_name,
			price: contract.price,
			deliveryDate: formatDateWithShortMonth(contract.delivery_date),
			contractStatus: contract.status_enum,
			created_at: formatDateWithShortMonth(contract.created_at),
			sponsorship_title: contract.sponsorship_title,
		};
	});
};

// PROJECT CONTRACTS
// MAP THE PROJECT CONTRACTS TO SUMMARY CONTRACTS
export const mappingProjectContracts = (contracts: ProjectContractType[]): ProjectContractSummaryType[] => {
	return contracts.map((contract: ProjectContractType) => {
		return {
			orderId: contract.id,
			tier: contract.tier?.name,
			vLancer: contract.seller.full_name,
			vClient: contract.customer.full_name,
			budgetType: contract.application.budget_enum,
			budgetValue: contract.application.budget_value,
			deliveryDate: formatDateWithShortMonth(contract.delivery_date),
			contractStatus: contract.status_enum,
			created_at: formatDateWithShortMonth(contract.created_at),
			project_title: contract.project_title,
		};
	});
};

// _________________Chat_V2________________________________

export function formatTimestamp(timestamp: number): string {
	if (!timestamp) return '';

	// Convert seconds to milliseconds if necessary
	if (timestamp < 10000000000) {
		// Likely in seconds
		timestamp *= 1000;
	}

	const date = typeof timestamp === 'number' ? new Date(timestamp) : timestamp;

	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
	const year = date.getFullYear();

	let hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const period = hours >= 12 ? 'PM' : 'AM';

	// Convert to 12-hour format
	hours = hours % 12;
	const formattedHours = (hours === 0 ? 12 : hours).toString().padStart(2, '0');

	return `${day}-${month}-${year} ${formattedHours}:${minutes} ${period}`;
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
	return (
		arr1.length === arr2.length && arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]))
	);
}

export const countEditorCharacters = (htmlString: string): number => {
	// Create a DOM parser
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');

	// Extract only the text content
	const plainText = doc.body.textContent || '';

	// Count the characters in the plain text
	return plainText.length;
};

export function hasObjectChanged(originalObj: Record<string, any>, compareObj: Record<string, any>): boolean {
	// Check if they have the same keys
	if (originalObj && compareObj) {
		const keys1 = Object.keys(originalObj);
		const keys2 = Object.keys(compareObj);

		if (keys1.length !== keys2.length) {
			return true; // Objects have a different number of keys
		}

		// Check if any key's value is different
		for (const key of keys1) {
			if (originalObj[key] !== compareObj[key]) {
				return true; // A difference is found
			}
		}
	}

	return false; // No differences
}

export function isMediaPostType(value: string): value is MediaPostType {
	return ['reel', 'highlight', 'story'].includes(value);
}
export const handleEventsBackButton = ({ setSection, form }: { setSection: (section: string) => void; form: any }) => {
	setSection('main');
	// form.setFieldValue('social_post_life_event', {});
};
export const modifyPrivacyOptions = ({
	isSocialPage,
	isSocialGroup,
	t,
	tCommon,
}: {
	isSocialPage?: boolean;
	isSocialGroup?: boolean;
	t: (s: string) => string;
	tCommon: (s: string) => string;
}) => {
	const privacyOptions: PrivacyType[] = _privacyOptions(t, tCommon)
		.map((option, i) => {
			if (i === 1) {
				if (isSocialPage) {
					return [
						{
							id: 5,
							name: 'Page followers',
							description: 'Only Your Page Followers Will See Your Asset',
							icon: <PageFollowers height={24} width={24} strokeOpacity="100" />,
							value: 'followers',
						},
						option,
					];
				}
				if (isSocialGroup) {
					return [
						{
							id: 5,
							name: 'Group Members',
							description: 'Only Your Group Members Will See Your Asset',
							icon: <GroupMembers height={24} width={24} strokeOpacity="100" fill="" />,
							value: 'members',
						},
						option,
					];
				} else return option;
			} else return option;
		})
		.flat();
	return privacyOptions;
};
// WEBINAR HELPERS
export const calculateTimeLeft = (startDate: string): CountdownTime => {
	const currentTime = new Date().getTime();
	const targetTime = new Date(startDate).getTime();
	const difference = targetTime - currentTime;

	if (difference <= 0) {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		};
	}

	const days = Math.floor(difference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
	const minutes = Math.floor((difference / (1000 * 60)) % 60);
	const seconds = Math.floor((difference / 1000) % 60);

	return { days, hours, minutes, seconds };
};

export const calcVideoEllapsedTime = (starting_date: string) => {
	const webinarStartTime = new Date(starting_date).getTime();
	const currentTime = new Date().getTime();
	const elapsedTime = currentTime - webinarStartTime;
	return elapsedTime / 1000;
};

export const getActiveInteractiveElements = (
	webinar: WebinarType,
	webinarVideoRef: React.RefObject<HTMLVideoElement>,
	setActivePolls: React.Dispatch<React.SetStateAction<WebinarPollType[]>>,
	setActiveOffers: React.Dispatch<React.SetStateAction<WebinarOfferType[]>>,
	setActiveHandouts: React.Dispatch<React.SetStateAction<WebinarHandoutType[]>>
) => {
	const videoElement = webinarVideoRef.current;
	if (!videoElement || !webinar.polls || !webinar.offers || !webinar.handouts) return;

	const currentTime = videoElement.currentTime;

	const activePolls = webinar.polls.filter((element) => {
		const elementTimeInSeconds =
			Number(element.scheduled_at_hours) * 3600 +
			Number(element.scheduled_at_minutes) * 60 +
			Number(element.scheduled_at_seconds);
		return currentTime >= elementTimeInSeconds;
	});
	const activeOffers = webinar.offers.filter((element) => {
		const elementTimeInSeconds =
			Number(element.scheduled_at_hours) * 3600 +
			Number(element.scheduled_at_minutes) * 60 +
			Number(element.scheduled_at_seconds);
		return currentTime >= elementTimeInSeconds;
	});
	const activeHandouts = webinar.handouts.filter((element) => {
		const elementTimeInSeconds =
			Number(element.scheduled_at_hours) * 3600 +
			Number(element.scheduled_at_minutes) * 60 +
			Number(element.scheduled_at_seconds);
		return currentTime >= elementTimeInSeconds;
	});

	setActivePolls((prev) => {
		const uniquePolls = new Map(prev.map((poll) => [poll.id, poll]));
		activePolls.forEach((poll) => uniquePolls.set(poll.id, poll));
		return Array.from(uniquePolls.values());
	});
	setActiveOffers((prev) => {
		const uniqueOffers = new Map(prev.map((offer) => [offer.id, offer]));
		activeOffers.forEach((offer) => uniqueOffers.set(offer.id, offer));
		return Array.from(uniqueOffers.values());
	});
	setActiveHandouts((prev) => {
		const uniqueHandouts = new Map(prev.map((handout) => [handout.id, handout]));
		activeHandouts.forEach((handout) => uniqueHandouts.set(handout.id, handout));
		return Array.from(uniqueHandouts.values());
	});
};

export const pollVoteHandler = async (
	webinarId: string,
	index: number,
	setActivePolls: Dispatch<SetStateAction<WebinarPollType[]>>,
	setWebinarPolls: Dispatch<SetStateAction<WebinarPollType[] | null>>,
	activePollsSelectedAnswers: { id: number; name: string }[],
	setActivePollsSelectedAnswersErrors: Dispatch<SetStateAction<string[]>>,
	pollVoteQuery: TypedLazyQueryTrigger<any, any, any>,
	setChangingVotes: Dispatch<SetStateAction<boolean[]>>
) => {
	if (activePollsSelectedAnswers[index].id) {
		const response: any = await pollVoteQuery(activePollsSelectedAnswers[index].id);
		if (response.isSuccess) {
			await updateWebinarEventStatus(webinarId, response.data.poll.id, 'Polls', 'update');
			setActivePolls((prev) => {
				const newPolls = [...prev];
				const newPoll = { ...newPolls[index], ...response.data.poll };
				newPolls[index] = newPoll;
				return newPolls;
			});
			setWebinarPolls((prev) => {
				if (!prev) return null;
				const newPolls = [...prev];
				const newPoll = { ...newPolls[index], ...response.data.poll };
				newPolls[index] = newPoll;
				return newPolls;
			});
			setChangingVotes((prev) => {
				const newVotes = [...prev];
				newVotes[index] = false;
				return newVotes;
			});
			setActivePollsSelectedAnswersErrors((prev) => {
				const newErrors = [...prev];
				newErrors[index] = '';
				return newErrors;
			});
		} else {
			return setActivePollsSelectedAnswersErrors((prev) => {
				const newErrors = [...prev];
				newErrors[index] = 'error occurred while voting for this poll, please try again';
				return newErrors;
			});
		}
	} else {
		setActivePollsSelectedAnswersErrors((prev) => {
			const newErrors = [...prev];
			newErrors[index] = 'Please select an answer';
			return newErrors;
		});
	}
};

export const formatNumberInto2Digits = (num: number) => String(num).padStart(2, '0');

export function formatDateBlogs(dateString: string): string {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = date.toLocaleString('default', { month: 'short' });
	const year = date.getFullYear();
	const time = date
		.toLocaleTimeString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true,
		})
		.replace(/^(\d+):(\d+) ([AP]M)$/i, (match, hour, minute, period) => `${hour}:${minute} ${period}`.toUpperCase());

	return `${day}-${month}-${year} ${time}`;
}

export function generateShareLink(
	moduleKey: string,
	id: string,
	type: 'profile' | 'asset' = 'profile',
	productTypePage = ''
): string | null {
	const urlTemplate = type === 'profile' ? MODULE_SHARE_LINKS[moduleKey] : MODULE_ASSETS_SHARE_LINKS[moduleKey];
	if (!urlTemplate) {
		console.error(`Invalid module key: ${moduleKey}`);
		return null;
	}
	const updatedUrl = urlTemplate.replace('{id}', id);

	return `vv-search/${productTypePage ? productTypePage + '/' : ''}${updatedUrl}`;
}
export function formatOrConvertDuration(input: number | string): string {
	if (typeof input === 'number') {
		const duration = new Date(input * 1000).toISOString().substr(11, 8);
		const [hours, minutes, seconds] = duration.split(':').map(Number);

		const formattedDuration = [
			hours ? `${hours} Hrs` : '',
			minutes ? `${minutes} Mins` : '',
			seconds ? `${seconds} Secs` : '',
		]
			.filter(Boolean)
			.join(' | ');

		return formattedDuration;
	} else if (typeof input === 'string') {
		const timeParts = input.split(':');
		const hours = parseInt(timeParts[0].replace(' H', ''), 10);
		const minutes = parseInt(timeParts[1].replace(' M', ''), 10);
		const seconds = parseInt(timeParts[2].replace(' S', ''), 10);

		const formattedDuration = [
			hours ? `${hours} Hrs` : '',
			minutes ? `${minutes} Mins` : '',
			seconds ? `${seconds} Secs` : '',
		]
			.filter(Boolean)
			.join(' ');

		return formattedDuration;
	}
	throw new Error('Invalid input type');
}

export function extractErrors(errors: Record<string, any>): string[] {
	const messages: string[] = [];

	function traverse(obj: any): void {
		if (typeof obj === 'string') {
			messages.push(obj);
		} else if (Array.isArray(obj)) {
			obj.forEach(traverse);
		} else if (typeof obj === 'object' && obj !== null) {
			Object.values(obj).forEach(traverse);
		}
	}

	traverse(errors);
	return messages;
}

export function formatCurrency(value: string): string {
	const num = parseFloat(value);
	if (isNaN(num)) return '$0';

	if (num >= 1_000_000) {
		return `$${(num / 1_000_000).toFixed(1)}m`;
	} else if (num >= 1_000) {
		return `$${(num / 1_000).toFixed(1)}k`;
	} else if (num >= 100) {
		return `$${num.toFixed(0)}`;
	}

	return `$${num}`;
}

// Calculates the total size of an array of files in megabytes.
export const calculateTotalSize = (files: any) => {
	let totalSize = 0;
	files?.map((file: any) => {
		totalSize = totalSize + file.file_size;
	});
	return (totalSize / 1000000).toFixed(2).toLocaleString();
};

export const transformTimeFormat = (timeStr: string = '00 H: 00 M: 00 S') => {
	const [hours, mins] = timeStr.split(' H: ').map((part) => part.split(' ')[0]);
	return `${parseInt(hours)} Hours ${parseInt(mins)} Mins`;
};
type CustomDate = {
	calendar: {
		identifier: 'gregory';
	};
	era: 'AD';
	year: number;
	month: number;
	day: number;
};

export const getCustomDate = (date: Date = new Date()): CustomDate => {
	return {
		calendar: { identifier: 'gregory' },
		era: 'AD',
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate(),
	};
};
