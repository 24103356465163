const EpisodeIcon = () => {
	return (
		<>
			<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_5319_473133)">
					<path
						d="M0.94375 2.14424C0.765625 2.23174 0.64375 2.35986 0.559375 2.54736C0.503125 2.66611 0.5 2.86924 0.5 6.75049C0.5 10.6349 0.503125 10.8349 0.559375 10.9536C0.634375 11.1224 0.79375 11.2755 0.984375 11.363L1.14062 11.438H8.5H15.8594L16.0156 11.363C16.2063 11.2755 16.3656 11.1224 16.4406 10.9536C16.4969 10.8349 16.5 10.6349 16.5 6.75049C16.5 2.86924 16.4969 2.66611 16.4406 2.54736C16.3562 2.35986 16.2344 2.23174 16.0563 2.14424L15.8938 2.06299H8.5H1.10625L0.94375 2.14424ZM15.775 2.78799C15.8281 2.84111 15.8281 10.6599 15.775 10.713C15.7219 10.7661 1.27812 10.7661 1.225 10.713C1.17188 10.6599 1.17188 2.84111 1.225 2.78799C1.27812 2.73486 15.7219 2.73486 15.775 2.78799Z"
						fill="url(#paint0_linear_5319_473133)"
					/>
					<path
						d="M6.80221 4.21671C6.76784 4.23234 6.71159 4.27609 6.67721 4.31359C6.61471 4.38234 6.61471 4.43234 6.60534 6.70734C6.59596 9.30109 6.58659 9.17921 6.80846 9.27296C6.87096 9.29796 6.96159 9.31046 7.01159 9.30109C7.05846 9.29171 7.82409 8.76671 8.71159 8.13859C9.94909 7.25734 10.3366 6.96671 10.371 6.88859C10.4366 6.75734 10.4022 6.59796 10.2928 6.49484C10.2241 6.43234 7.57096 4.53546 7.16784 4.26046C7.05846 4.18859 6.91784 4.16984 6.80221 4.21671ZM8.37096 5.97296C8.95221 6.38546 9.43034 6.73234 9.43346 6.74484C9.43659 6.76046 7.89596 7.87296 7.34909 8.24796C7.28659 8.28859 7.28659 8.28546 7.28659 6.75421C7.28659 5.91046 7.29284 5.21984 7.29909 5.21984C7.30846 5.21984 7.78971 5.55734 8.37096 5.97296Z"
						fill="url(#paint1_linear_5319_473133)"
					/>
					<path
						d="M8.20357 12.3725C7.96607 12.4538 7.77545 12.5756 7.62857 12.7413C7.50045 12.885 7.34732 13.1569 7.34732 13.2413C7.34732 13.2725 6.6692 13.2819 4.0692 13.2819C2.10045 13.2819 0.756696 13.2944 0.712946 13.31C0.444196 13.4131 0.425446 13.8038 0.681696 13.935C0.731696 13.96 1.67545 13.9694 4.0317 13.9694H7.31607L7.4067 14.1694C7.56295 14.5131 7.82857 14.7538 8.1942 14.8756C8.3567 14.9319 8.44732 14.9413 8.67857 14.9319C8.92232 14.9225 8.99107 14.9069 9.17232 14.8163C9.45045 14.6788 9.68795 14.4381 9.80357 14.1725L9.89107 13.9694H13.0754C15.3567 13.9694 16.2754 13.96 16.3254 13.935C16.4473 13.8725 16.5036 13.7756 16.5036 13.6225C16.5036 13.4694 16.4348 13.3631 16.2942 13.31C16.2504 13.2944 14.9504 13.2819 13.0567 13.2819H9.8942L9.7942 13.0694C9.70045 12.8694 9.50982 12.6475 9.3192 12.5131C9.0567 12.3288 8.52545 12.2631 8.20357 12.3725ZM8.8817 13.0725C9.06607 13.1725 9.1942 13.36 9.21607 13.56C9.2817 14.2538 8.36295 14.5194 8.05045 13.8975C7.95982 13.7131 7.95045 13.5788 8.02232 13.4131C8.16607 13.0631 8.56295 12.9069 8.8817 13.0725Z"
						fill="url(#paint2_linear_5319_473133)"
					/>
				</g>
				<defs>
					<linearGradient
						id="paint0_linear_5319_473133"
						x1="6.75926"
						y1="2.52464"
						x2="15.9469"
						y2="10.6961"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_5319_473133"
						x1="8.08917"
						y1="4.44279"
						x2="11.4893"
						y2="5.76054"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_5319_473133"
						x1="6.76066"
						y1="12.4482"
						x2="8.23466"
						y2="17.148"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#016DEA" />
						<stop offset="1" stopColor="#FA0101" />
					</linearGradient>
					<clipPath id="clip0_5319_473133">
						<rect width="16" height="16" fill="white" transform="translate(0.5 0.5)" />
					</clipPath>
				</defs>
			</svg>
		</>
	);
};

export default EpisodeIcon;
